    /**
 * Document Management Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import {validate as Validate, clean, format as Format, getCheckDigit} from 'rut.js'

import {
	Pagination,
	PaginationItem,
	PaginationLink,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge
} from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { NotificationManager } from 'react-notifications';
import Avatar from '@material-ui/core/Avatar';
import { format, parseISO  } from 'date-fns';
// api
import api from 'Api';

// delete confirmation dialog
import DeleteConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import DeleteManyConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteManyConfirmationDialog';
import ActivateManyConfirmationDialog from 'Components/DeleteConfirmationDialog/ActivateManyConfirmationDialog';
import DeActivateManyConfirmationDialog from 'Components/DeleteConfirmationDialog/DeActivateManyConfirmationDialog';

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

// add new document form
import AddNewDocumentForm from './AddNewDocumentForm';

// update document form
import UpdateDocumentForm from './UpdateDocumentForm';

import { TableCell } from '@material-ui/core'

const oldRender = TableCell.render

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

export default class Documents extends React.Component {
        
        state = {
		all: false,
		documents: null, // initial document data
		selectedDocument: null, // selected document to perform operations
		loading: true, // loading activity
                loading2: true, // loading activity
		addNewDocumentModal: false, // add new document form modal
		addNewDocumentDetail: {
			id: '',
			name: '',
			requerido: '',
			estado: 1,
			created_at: '',
                        expirated_at : 0,
			checked: false
		},
		openViewDocumentDialog: false, // view document dialog box
		editDocument: null,
		allSelected: false,
		selectedDocuments: 0,
                validate: {
                    nameState : '',
                    emailState: '',
                    rutState : ''
                  }
	}
        
        componentWillMount(){
            const { location, history} = this.props;
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rol');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {
                  let rol = response.data.rol;
                  if(rol != 'administrador' ){
                        history.push('/session/404');
                  }
                  this.Init();
              }
            }, (error) => {
                console.log(error);
                
            });
        }
        
        Init(){

            axios.get(`/documents`)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].id);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].requerido);
                            localArray.push(res.data[i].created_at);
                            localArray.push(res.data[i].estado);
                            localArray.push(res.data[i].expirated_at);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }

                        this.setState({ documents: res.data, loading2: false,loading: false, dataTable: result, rowsSelected: [] });
                    })
                .catch(error => {
                   NotificationManager.error(error.message);
                   this.setState({ loading2: false });
             })
          }
          
          /**
	 * Open Add New Document Modal
	 */
	opnAddNewDocumentModal(e) {
            e.preventDefault();
            this.setState({ addNewDocumentModal: true,
                validate: {
                    nameState : '',
                    emailState: '',
                    rutState : ''
                  } });
	}

	/**
	 * On Reload
	 */
        onReload(e) {
            this.setState({ loading2: true });
            axios.get(`/documents`)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].id);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].requerido);
                            localArray.push(res.data[i].created_at);
                            localArray.push(res.data[i].estado);
                            localArray.push(res.data[i].expirated_at);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading2: false, dataTable: result, documents: res.data, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading2: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
	}
        
        /**
	 * On Change Add New Document Details
	 */
	onChangeAddNewDocumentDetails(key, value) {
		this.setState({
			addNewDocumentDetail: {
				...this.state.addNewDocumentDetail,
				[key]: value
			}
		});
	}
        
        /**
	 * On Add & Update Document Modal Close
	 */
	onAddUpdateDocumentModalClose() {
		this.setState({ addNewDocumentModal: false, editDocument: null })
	}
        
        /**
	 * On Update User Details
	 */
	onUpdateDocumentDetails(key, value) {
		this.setState({
			editDocument: {
				...this.state.editDocument,
				[key]: value
			}
		});
	}
        
        /**
	 * View Document Detail Hanlder
	 */
	viewDocumentDetail(data) {
                console.log(data);
		this.setState({ openViewDocumentDialog: true, selectedDocument: data });
	}

	/**
	 * On Edit Document
	 */
	onEditDocument(document, index) {
            this.setState({ addNewDocumentModal: true, editDocument: document, editDocumentIndex : index, validate: {
                nameState : ''
               }});
	}
        
        /**
	 * Add New Document
	 */
	addNewDocument(e) {
		const { name } = this.state.addNewDocumentDetail;
                const { validate } = this.state;
                
                this.validateName(name);
                
                if(validate.nameState == 'has-danger'){
                    e.preventDefault();
                    return false;
                }

                let newDocument = {
                        ...this.state.addNewDocumentDetail,
                        id: new Date().getTime()
                }
                
               // newUser.rut = clean(newUser.rut);

                this.setState({ loading2: true, addNewDocumentModal: false });

                axios.post(`/documents`, newDocument)
                    .then(
                        (res) => {

                            var result = [];
                            for(var i in res.data.documents){
                                var localArray = [];
                                localArray.push(res.data.documents[i].id);
                                localArray.push(res.data.documents[i].name);
                                localArray.push(res.data.documents[i].requerido);
                                localArray.push(res.data.documents[i].created_at);
                                localArray.push(res.data.documents[i].estado);
                                localArray.push(res.data.documents[i].expirated_at);
                                localArray.push(res.data.documents[i]);
                                result.push(localArray);
                            }
                            
                            this.setState({addNewDocumentDetail: {
                                id: '',
                                name: '',
                                requerido: '',
                                estado: 1,
                                created_at: '',
                                expirated_at : 0,
                                checked: false
                            }});

                            this.setState({ documents: res.data.documents, loading2: false,loading: false, dataTable: result, rowsSelected: [] });
                            NotificationManager.success(res.data.message);

                        })
                    .catch((error) => {

                        this.setState({ loading2: false });
                        if (error.response) {
                            if(Array.isArray(error.response.data.message)){
                                for(let i=0;i<error.response.data.message.length;i++){
                                    NotificationManager.error(error.response.data.message[i]);
                                }
                            } else {
                                NotificationManager.error(error.response.data.message);
                            }
                          } else if (error.request) {
                            NotificationManager.error(error.request);
                          } else {
                            NotificationManager.error(error.message);
                          }
                     })

            e.preventDefault();
	}
        
        /**
	 * On Delete
	 */
	onDelete(data) {
		this.refs.deleteConfirmationDialog.open();
		this.setState({ selectedDocument: data });
	}
        
        /**
	 * Update Document
	 */
	updateDocument(e) {
                
		const { editDocument, editDocumentIndex, validate } = this.state;

                this.validateName(editDocument.name);
                
                if(validate.nameState == 'has-danger'){
                    e.preventDefault();
                    return false;
                }

		let documents = this.state.documents;
		documents[editDocumentIndex] = editDocument;
		this.setState({ loading2: true, addNewDocumentModal: false });
                
                    axios.put(`/documents`, documents[editDocumentIndex])
                        .then(
                            (res) => {
                                
                                var result = [];
                                for(var i in res.data.documents){
                                    var localArray = [];
                                    localArray.push(res.data.documents[i].id);
                                    localArray.push(res.data.documents[i].name);
                                    localArray.push(res.data.documents[i].requerido);
                                    localArray.push(res.data.documents[i].created_at);
                                    localArray.push(res.data.documents[i].estado);
                                    localArray.push(res.data.documents[i].expirated_at);
                                    localArray.push(res.data.documents[i]);
                                    result.push(localArray);
                                }
     
                                this.setState({ documents: res.data.documents, loading2: false , dataTable: result, rowsSelected: [], editDocument : null });
                                NotificationManager.success(res.data.message);
   
                            })
                        .catch((error) => {

                        this.setState({ loading2: false, editDocument : null });
                        if (error.response) {
                            NotificationManager.error(error.response.data.message);
                          } else if (error.request) {
                            NotificationManager.error(error.request);
                          } else {
                            NotificationManager.error(error.message);
                          }
                         })
                    e.preventDefault();
              
	}
        
        /**
	 * Delete Document Permanently
	 */
	deleteDocumentPermanently(e) {
            const { selectedDocument } = this.state;

            this.refs.deleteConfirmationDialog.close();
            this.setState({ loading2: true });

            axios.delete(`/documents`, {data : selectedDocument})
                    .then(
                        (res) => {

                            var result = [];
                            for(var i in res.data.documents){
                                var localArray = [];
                                localArray.push(res.data.documents[i].id);
                                 localArray.push(res.data.documents[i].name);
                                 localArray.push(res.data.documents[i].requerido);
                                 localArray.push(res.data.documents[i].created_at);
                                 localArray.push(res.data.documents[i].estado);
                                 localArray.push(res.data.documents[i].expirated_at);
                                 localArray.push(res.data.documents[i]);
                                result.push(localArray);
                            }

                            this.setState({ documents: res.data.documents, loading2: false,loading: false, dataTable: result, rowsSelected: [] });
                            NotificationManager.success(res.data.message);

                        })
                    .catch((error) => {
                    this.setState({ loading2: false });
                        /*console.log(error); console.log(error.response);
                       NotificationManager.error(error.message);
                       this.setState({ loading2: false });*/
                    if (error.response) {
                        // La respuesta fue hecha y el servidor respondió con un código de estado
                        // que esta fuera del rango de 2xx
                        //console.log(error.response.data);
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        // La petición fue hecha pero no se recibió respuesta
                        // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                        // http.ClientRequest en node.js
                        NotificationManager.error(error.request);
                      } else {
                        // Algo paso al preparar la petición que lanzo un Error
                        NotificationManager.error(error.message);
                      }
                    })
                e.preventDefault();
            
	}
        
        /**
	 * Delete Document Permanently
	 */
	deleteManyDocumentPermanently(e) {

            const { allSelected } = this.state;
            this.refs.deleteManyConfirmationDialog.close();
            this.setState({ loading2: true });

            axios.delete(`/documents-many`, {data : {'ids' : allSelected}})
                .then(
                    (res) => {
                        var result = [];
                        for(var i in res.data.documents){
                            var localArray = [];
                            localArray.push(res.data.documents[i].id);
                            localArray.push(res.data.documents[i].name);
                            localArray.push(res.data.documents[i].requerido);
                            localArray.push(res.data.documents[i].created_at);      
                            localArray.push(res.data.documents[i].estado);
                            localArray.push(res.data.documents[i].expirated_at);
                            localArray.push(res.data.documents[i]);
                            result.push(localArray);
                           }
                        this.setState({ documents: res.data.documents,  loading2: false, dataTable: result, rowsSelected: [] });
                        NotificationManager.success(res.data.message);
                    })
                .catch(error => {
                    this.setState({ loading2: false });

                   if (error.response) {
                       if(Array.isArray(error.response.data.message)){
                           for(let i=0;i<error.response.data.message.length;i++){
                               NotificationManager.error(error.response.data.message[i]);
                           }
                       } else {
                           NotificationManager.error(error.response.data.message);
                       }
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
            
	}
        
        /**
	 * Deactivate Document Permanently
	 */
	deActivateManyDocumentPermanently(e) {

            const { allSelected } = this.state;
            this.refs.deActivateManyConfirmationDialog.close();
            this.setState({ loading2: true });

            axios.put(`/documents-many-deactivate`, {'ids' : allSelected})
                .then(
                    (res) => {
                        var result = [];
                        for(var i in res.data.documents){
                            var localArray = [];
                            localArray.push(res.data.documents[i].id);
                            localArray.push(res.data.documents[i].name);
                            localArray.push(res.data.documents[i].requerido);
                            localArray.push(res.data.documents[i].created_at);
                            localArray.push(res.data.documents[i].estado);
                            localArray.push(res.data.documents[i].expirated_at);
                            localArray.push(res.data.documents[i]);
                            result.push(localArray);
                           }
                        this.setState({ documents: res.data.documents,  loading2: false, dataTable: result, rowsSelected: [] });
                        NotificationManager.success(res.data.message);
                    })
                .catch(error => {
                    this.setState({ loading2: false });

                   if (error.response) {
                       if(Array.isArray(error.response.data.message)){
                           for(let i=0;i<error.response.data.message.length;i++){
                               NotificationManager.error(error.response.data.message[i]);
                           }
                       } else {
                           NotificationManager.error(error.response.data.message);
                       }
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
            
	}
        
        /**
	 * Activate Document Permanently
	 */
	activateManyDocumentPermanently(e) {

            const { allSelected } = this.state;
            this.refs.activateManyConfirmationDialog.close();
            this.setState({ loading2: true });

            axios.put(`/documents-many-activate`, {'ids' : allSelected})
                .then(
                    (res) => {
                        var result = [];
                        for(var i in res.data.documents){
                            var localArray = [];
                            localArray.push(res.data.documents[i].id);
                            localArray.push(res.data.documents[i].name);
                            localArray.push(res.data.documents[i].requerido);
                            localArray.push(res.data.documents[i].created_at);
                            localArray.push(res.data.documents[i].estado);
                            localArray.push(res.data.documents[i].expirated_at);
                            localArray.push(res.data.documents[i]);
                            result.push(localArray);
                           }
                        this.setState({ documents: res.data.documents,  loading2: false, dataTable: result, rowsSelected: [] });
                        NotificationManager.success(res.data.message);
                    })
                .catch(error => {
                    this.setState({ loading2: false });

                   if (error.response) {
                       if(Array.isArray(error.response.data.message)){
                           for(let i=0;i<error.response.data.message.length;i++){
                               NotificationManager.error(error.response.data.message[i]);
                           }
                       } else {
                           NotificationManager.error(error.response.data.message);
                       }
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
            
	}
      
        validateName(name){
            const { validate } = this.state;
            if(name == "" ){
                validate.nameState = 'has-danger';
            } else {
                validate.nameState = 'has-success';
            }
            this.setState({ validate });
        }
        
        render() {
            
            const { documents, loading,loading2, selectedDocument, editDocument, allSelected, selectedDocuments, dataTable, editDocumentIndex  } = this.state;
            
            const columns = [ 
                            {"name":"ID",
                             "label":"ID",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> )
                                }},
                            {"name":"Nombre",
                             "label":"Nombre",
                                options: {
                                    filter: true,
                                    options: { sortDirection: 'desc' },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                }},
                            {"name":"Requerido","label":"Requerido",
                                options: {
                                    filter: true,
                                    sort: false,
                                    customBodyRender: (value) => {
                                        return value  == 1 ? 'Requerido': 'Opcional'},
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                }},
                            {"name": "Registro","label":"Registro",
                                options: {
                                    filter: false,
                                    customBodyRender: (value) => {
                                        return format(parseISO(value), 'dd-MM-yyyy H:mm')
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                              },},
                            {"name":"Estado","label":"Estado",
                                options: {
                                    filter: true,
                                    sort: true,
                                    customBodyRender: (value) => {
                                        return value  == 1 ? 'Habilitado': 'Deshabilitado'},
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> )
                                }
                              },{"name":"Expiración Días","label":"Expiración Días",
                                options: {
                                    filter: false,
                                    sort: false,
                                    customBodyRender: (value) => {
                                        return value  == 0 ? 'No': value},
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> )
                                }
                              },{"name":"Acciones","label":"Acciones",
                                
                                options: {
                                    filter: false,
                                    sort: false,
                                    download: false,
                                    customBodyRender: (value, tableMeta) => { 
                                        return (
                                                <div className="list-action">
                                                <button type="button" className="rct-link-btn" onClick={() => this.viewDocumentDetail(value)}><i className="ti-eye"></i></button>
                                                <button type="button" className="rct-link-btn" onClick={() => this.onEditDocument(value,tableMeta.rowIndex)}><i className="ti-pencil"></i></button>
                                                <button type="button" className="rct-link-btn" onClick={() => this.onDelete(value)}><i className="ti-close"></i></button>
                                                </div>
                                            );
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                              }}];
                    const data = dataTable;
                    
                    const options = {
			filterType: 'dropdown',
                        download: false,
                        print: false,
                        tableBodyMaxHeight:'500px',
                        tableBodyHeight:'500px',
                        downloadOptions : {filename: 'sistema_documentos.csv', separator: ';'},
                        textLabels: {
                            body: {
                              noMatch: "Lo sentimos, no se encotraron registros",
                              toolTip: "Ordenar",
                              columnHeaderTooltip: column => `Ordenar por ${column.label}`
                            },
                            pagination: {
                              next: "Siguiente",
                              previous: "Anterior",
                              rowsPerPage: "Fillas por página:",
                              displayRows: "de",
                            },
                            toolbar: {
                              search: "Buscar",
                              //downloadCsv: "Descargar CSV",
                              //print: "Print",
                              viewColumns: "Ver Columnas",
                              filterTable: "Filtrar",
                            },
                            filter: {
                              all: "Todas",
                              title: "FILTRAR",
                              reset: "REINICIAR",
                            },
                            viewColumns: {
                              title: "Mostrar Columnas",
                              titleAria: "Mostrar/Ocultar Columnas",
                            },
                            selectedRows: {
                              text: "filas(s) seleccionadas",
                              delete: "Eliminar",
                              deleteAria: "Eliminar Filas Seleccionadas",
                            }
                          },
                          /*sortOrder: {
                                name: 'Nombre',
                                direction: 'asc',
                            },*/
                            onRowsDelete: (rowsDeleted) => {
                                let documents = this.state.documents;
                                let idsToDelete = rowsDeleted.data.map (item => item.dataIndex);
                                let obj = [];
                                for(let i=0;i<idsToDelete.length;i++){
                                    obj.push(documents[idsToDelete[i]].id)
                                }
      
                                this.refs.deleteManyConfirmationDialog.open();
                                this.setState({ allSelected: obj });
                    
                                return false;
                            },
                            rowsSelected: this.state.rowsSelected,
                            onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
                              this.setState({ rowsSelected: rowsSelected });
                            },
                            
                            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                                
                                 const handleClick = (e, accion) => {
                                    let documents = this.state.documents;
                                    let idsToDelete = selectedRows.data.map (item => item.dataIndex);
                                    let obj = [];
                                    for(let i=0;i<idsToDelete.length;i++){
                                        obj.push(documents[idsToDelete[i]].id)
                                    }
                                    if(accion == 'delete'){
                                        this.refs.deleteManyConfirmationDialog.open();
                                    } else if (accion == 'activate') {
                                        this.refs.activateManyConfirmationDialog.open();
                                    } else if (accion == 'deactivate') {
                                        this.refs.deActivateManyConfirmationDialog.open();
                                    }
                                    this.setState({ allSelected: obj });
                               
                                    e.preventDefault();
                                 };

                                return (
                                        <div>
                                                <Tooltip title="Habilitar">
                                                    <IconButton onClick={(e) => handleClick(e,'activate')} aria-label="Habilitar" >
                                                      <span edge="start" aria-label="Habilitar" className="material-icons">check_circle</span>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Deshabilitar">
                                                    <IconButton onClick={(e) => handleClick(e,'deactivate')}   aria-label="Deshabilitar" >
                                                      <span edge="start" aria-label="Deshabilitar" className="material-icons">not_interested</span>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Eliminar">
                                                    <IconButton onClick={(e) => handleClick(e,'delete')} className="mr-4"  aria-label="Eliminar">
                                                      <span edge="start" aria-label="Deshabilitar" className="material-icons">delete</span>
                                                    </IconButton>
                                                </Tooltip>
                                        </div>
                                        )
                         },
   
                    };
		
            return ( <div className="document-management">            
                <Helmet>
                        <title>{process.env.MIX_APP_NAME} | Gestión de Documentos</title>
                        <meta name="description" content="Gestión de Usuarios" />
                </Helmet>
                <PageTitleBar
                        title={<IntlMessages id="sidebar.documentManagement" />}
                        match={this.props.match}
                />
                <RctCollapsibleCard  fullBlock>
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="#" onClick={(e) => this.onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"></i></a>
                            <a href="#" onClick={(e) => this.opnAddNewDocumentModal(e)} color="primary" className="btn-sm btn-outline-default mr-10">Crear Nuevo <i className="zmdi zmdi-plus"></i></a>
                        </div>
                    </div>
                    <MUIDataTable className="table table-middle table-hover mb-0"
                            title={"Listado de documentos"}
                            data={data}
                            columns={columns}
                            options={options}
                    />
                    { loading2 &&
                            <RctSectionLoader />
                    }    
                </RctCollapsibleCard>
                <DeleteConfirmationDialog
                            ref="deleteConfirmationDialog"
                            title="¿Seguro que quieres eliminar el documento?"
                            message="Esto lo eliminará permanentemente."
                            onConfirm={(e) => this.deleteDocumentPermanently(e)}
                    />
                    <DeleteManyConfirmationDialog
                            ref="deleteManyConfirmationDialog"
                            title="¿Seguro que quieres eliminar el/los documentos(s) seleccionado(s)?"
                            message="Esto lo(s) eliminará permanentemente."
                            onConfirm={(e) => this.deleteManyDocumentPermanently(e)}
                    />
                    <ActivateManyConfirmationDialog
                            ref="activateManyConfirmationDialog"
                            title="¿Seguro que quieres habilitar el/los documentos(s) seleccionado(s)?"
                            message="Esto lo(s) habilitará permanentemente."
                            onConfirm={(e) => this.activateManyDocumentPermanently(e)}
                    />
                    <DeActivateManyConfirmationDialog
                            ref="deActivateManyConfirmationDialog"
                            title="¿Seguro que quieres deshabilitar el/los documentos(s) seleccionado(s)?"
                            message="Esto lo(s) deshabilitará permanentemente."
                            onConfirm={(e) => this.deActivateManyDocumentPermanently(e)}
                    />
                    <Modal isOpen={this.state.addNewDocumentModal} toggle={() => this.onAddUpdateDocumentModalClose()} backdrop="static"> 
                            <ModalHeader toggle={() => this.onAddUpdateDocumentModalClose()}>
                                    {editDocument === null && this.state.addNewDocumentModal  === true ?
                                            'Crear Nuevo Documento' : 'Actualizar Documento'
                                    }
                            </ModalHeader>
                            <ModalBody>
                                    {editDocument === null ?
                                            <AddNewDocumentForm
                                                    addNewDocumentDetails={this.state.addNewDocumentDetail}
                                                    onChangeAddNewDocumentDetails={this.onChangeAddNewDocumentDetails.bind(this)}
                                                    validate={this.state.validate}
                                                    validateName={this.validateName.bind(this)}
                                            />
                                            : <UpdateDocumentForm document={editDocument} onUpdateDocumentDetail={this.onUpdateDocumentDetails.bind(this)} index={editDocumentIndex} 
                                                    validate={this.state.validate}
                                            />
                                    }
                            </ModalBody>
                            <ModalFooter>
                                    {editDocument === null && this.state.addNewDocumentModal === true ?
                                            <Button variant="contained" className="text-white btn-success" onClick={(e) => this.addNewDocument(e)}>Crear</Button>
                                            : <Button variant="contained" color="primary" className="text-white" onClick={(e) => this.updateDocument(e)}>Actualizar</Button>
                                    }
                                    {' '}
                                    <Button variant="contained" className="text-white btn-danger" onClick={() => this.onAddUpdateDocumentModalClose()}>Cancelar</Button>
                            </ModalFooter>
                    </Modal>
                    <Dialog
                            onClose={() => this.setState({ openViewDocumentDialog: false })}
                            open={this.state.openViewDocumentDialog}
                    >
                            <DialogContent>
                                    {selectedDocument !== null &&
                                            <div>
                                                    <div className="clearfix d-flex">
                                                            <div className="media pull-left">
                                                                    <div className="media-body">
                                                                            <p>ID: <span className="fw-bold">{selectedDocument.id}</span></p>
                                                                            <p>Nombre: <span className="fw-bold">{selectedDocument.name}</span></p>
                                                                            <p>Requerido: <span className="fw-bold">{selectedDocument.requerido == 1 ? 'Requerido' : 'Opcional' }</span></p>
                                                                            <p>Registro: <span className="fw-bold">{format(parseISO(selectedDocument.created_at), 'dd-MM-yyyy H:mm')}</span></p>
                                                                            <p>Notifiación previa expiración: <span className="fw-bold">{selectedDocument.expirated_at == 0 ? 'No' : selectedDocument.expirated_at + ' Día(s)' }</span></p>
                                                                            <p>Estado: <span className="fw-bold">{selectedDocument.estado == 1 ? 'Habilitado' : 'Inhabilitado' }</span></p>
                                                                    </div>
                                                            </div>
                                                    </div>
                                            </div>
                                    }
                            </DialogContent>
                    </Dialog>
            </div>);
                                
        }
}