/**
 * Add New User Form
 */
import React, {  Fragment }  from 'react';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { DateTimePicker, MuiPickersUtilsProvider,registerLocale } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";


const AddNewControlForm = ({ addNewControlDetails, onChangeAddNewControlDetails, onBlurRut, onFocusRut, onBlurRutChofer, onFocusRutChofer, validate, validateName, maxDate }) => (
    <Form>
         <FormGroup>
            <Label className="mb-1" for="tipo_con">Tipo de control</Label>
            <FormControl fullWidth>
                <Select name="tipo_con" name="tipo_con" value={addNewControlDetails.tipo_con} onChange={(e) => onChangeAddNewControlDetails('tipo_con', e.target.value)}
                >
                      <MenuItem value=''>Seleccione</MenuItem>  
                      <MenuItem value={'PERSONAS'}>PERSONAS</MenuItem>
                      <MenuItem value={'VEHÍCULOS'}>VEHÍCULOS</MenuItem>
                      <MenuItem value={'CAMIONES'}>CAMIONES</MenuItem>
                      <MenuItem value={'EMBARCACIONES'}>EMBARCACIONES</MenuItem>
                </Select>
              </FormControl>
        </FormGroup>
        {addNewControlDetails.tipo_con === 'PERSONAS' &&
        <FormGroup>
            <Label for="controlRut">Rut</Label>
            <Input
                type="text"
                name="controlRut"
                id="userRut"
                placeholder="Ingrese RUT"
                invalid={validate.rutState === "has-danger"}
                value={addNewControlDetails.rut}
                onFocus={(e) => onFocusRut(e.target.value,'nuevo')}
                onBlur={(e) => onBlurRut(e.target.value,'nuevo')}
                onChange={(e) => onChangeAddNewControlDetails('rut', e.target.value)}
            />
            <FormFeedback>
              RUT incorrecto, o dígito verificador no corresponde
            </FormFeedback>
        </FormGroup>
        }
        {addNewControlDetails.tipo_con === 'VEHÍCULOS' &&
        <FormGroup>
            <Label for="controlRutChofer">Rut Chofer</Label>
            <Input
                type="text"
                name="controlRutChofer"
                id="userRutChofer"
                placeholder="Ingrese RUT"
                invalid={validate.rutChoferState === "has-danger"}
                value={addNewControlDetails.rut_chofer}
                onFocus={(e) => onFocusRutChofer(e.target.value,'nuevo')}
                onBlur={(e) => onBlurRutChofer(e.target.value,'nuevo')}
                onChange={(e) => onChangeAddNewControlDetails('rut_chofer', e.target.value)}
            />
            <FormFeedback>
              RUT incorrecto, o dígito verificador no corresponde
            </FormFeedback>
        </FormGroup>
        }
        <FormGroup>
            <Label for="userName">Nombre</Label>
            <Input
                type="text"
                name="ControlName"
                id="controlName"
                invalid={validate.nameState === "has-danger"}
                placeholder="Ingrese Nombre"
                value={addNewControlDetails.nom_per}
                onChange={(e) => onChangeAddNewControlDetails('nom_per', e.target.value)}
            />
            <FormFeedback>
              Nombre no puede ser vacío o muy corto.
            </FormFeedback>
        </FormGroup>
        {(addNewControlDetails.tipo_con === 'VEHÍCULOS' || addNewControlDetails.tipo_con === 'EMBARCACIONES') &&
       <FormGroup>
            <Label for="controlPate">Patente/Matricula</Label>
            <Input
                type="text"
                name="ControlPate"
                id="ControlPate"
                invalid={validate.nameState === "has-danger"}
                placeholder="Ingrese Patente o Matrícula"
                value={addNewControlDetails.pate}
                onChange={(e) => onChangeAddNewControlDetails('pate', e.target.value)}
            />
            <FormFeedback>
              Patente no puede ser vacío o muy corto.
            </FormFeedback>
        </FormGroup>
        }
         <FormGroup>
            <Label for="h_in">Fecha Inicio</Label>
                <FormGroup>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <DateTimePicker
                                clearable
                                ampm={true}
                                maxDate={maxDate}
                                inputVariant="outlined"
                                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                fullWidth
                                format="dd/MM/yyyy HH:mm a"
                                value={addNewControlDetails.h_in}
                                onChange={(e) => onChangeAddNewControlDetails('h_in', e)}
                                />
                    </MuiPickersUtilsProvider>    
                </FormGroup>
        </FormGroup>
        <FormGroup  style={{display: 'none' }}>
            <Label for="h_sal">Fecha salida</Label>
                <FormGroup>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                                clearable
                                ampm={true}
                                inputVariant="outlined"
                                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                fullWidth
                                maxDate={maxDate}
                                format="dd/MM/yyyy HH:mm a"
                                value={addNewControlDetails.h_sal}
                                onChange={(e) => onChangeAddNewControlDetails('h_sal', e)}
                                />
                    </MuiPickersUtilsProvider>    
                </FormGroup>
        </FormGroup>
       {addNewControlDetails.tipo_con === 'PERSONAS' &&
       <FormGroup>
          <Label for="entre">Entrevista</Label>
            <Input
                type="text"
                name="entre"
                id="entre"
                placeholder="Ingrese entrevistado"
                value={addNewControlDetails.entre}
                onChange={(e) => onChangeAddNewControlDetails('entre', e.target.value)}
            />
       
       </FormGroup>
       }
       {  addNewControlDetails.tipo_con === 'EMBARCACIONES' &&
         <FormGroup>
          <Label for="nombre_naviera">Nombre Naviera</Label>
            <Input
                type="text"
                name="nombre_naviera"
                id="nombre_naviera"
                placeholder="Ingrese nombre naviera"
                value={addNewControlDetails.nombre_naviera}
                onChange={(e) => onChangeAddNewControlDetails('nombre_naviera', e.target.value)}
            />
       
       </FormGroup>
       }
       {  addNewControlDetails.tipo_con === 'EMBARCACIONES' &&
         <FormGroup>
          <Label for="tipo_movimiento">Tipo Movimiento</Label>
            <FormControl fullWidth>
                <Select name="tipo_movimiento" name="tipo_movimiento" value={addNewControlDetails.tipo_movimiento} onChange={(e) => onChangeAddNewControlDetails('tipo_movimiento', e.target.value)}
                >
                      <MenuItem value=''>Seleccione</MenuItem>  
                      <MenuItem value={'RETIRO'}>RETIRO</MenuItem>
                      <MenuItem value={'INGRESO'}>INGRESO</MenuItem>
                </Select>
              </FormControl>
       
       </FormGroup>
       }
       { (addNewControlDetails.tipo_con === 'CAMIONES' || addNewControlDetails.tipo_con === 'EMBARCACIONES') &&
         <FormGroup>
          <Label for="guia_des">Guia Despacho</Label>
            <Input
                type="number"
                name="guia_des"
                id="guia_des"
                placeholder="Ingrese guia despacho"
                value={addNewControlDetails.guia_des}
                onChange={(e) => onChangeAddNewControlDetails('guia_des', e.target.value)}
            />
       
       </FormGroup>
       }
       {addNewControlDetails.tipo_con === 'CAMIONES'  &&
        <FormGroup>
          <Label for="sello">Sello</Label>
            <Input
                type="text"
                name="sello"
                id="sello"
                placeholder="Ingrese sello"
                value={addNewControlDetails.sello}
                onChange={(e) => onChangeAddNewControlDetails('sello', e.target.value)}
            />
       
       </FormGroup>
       }
       {addNewControlDetails.tipo_con === 'CAMIONES' &&
        <FormGroup>
          <Label for="espe">Especie</Label>
            <Input
                type="text"
                name="espe"
                id="espe"
                placeholder="Ingrese especie"
                value={addNewControlDetails.espe}
                onChange={(e) => onChangeAddNewControlDetails('espe', e.target.value)}
            />
       
       </FormGroup>
       }
       {addNewControlDetails.tipo_con === 'CAMIONES'  &&
         <FormGroup>
          <Label for="canda">Candado</Label>
            <Input
                type="number"
                name="canda"
                id="canda"
                placeholder="Ingrese candado"
                value={addNewControlDetails.canda}
                onChange={(e) => onChangeAddNewControlDetails('canda', e.target.value)}
            />
       
       </FormGroup>
       }
       <FormGroup>
          <Label for="entre">{addNewControlDetails.interno == 1 ? 'Interno' : 'Externo'}</Label>
            <FormControl fullWidth>
                <Switch name="interno" color="primary" id="interno" checked={(addNewControlDetails.interno === 1 || addNewControlDetails.interno === true) ? true : false } 
                onChange={(e) => onChangeAddNewControlDetails('interno', e.target.checked)} aria-label="interno" />
              </FormControl>
       </FormGroup>
    </Form>
);

export default AddNewControlForm;
