  /**
 * RRHH Management Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Fab } from '@material-ui/core';
import {NumericFormat } from 'react-number-format';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
	Form,
	FormGroup,
	Label,
	
	FormText,
	Col,
	FormFeedback,
        Button as Btn,
	Input as In,


} from 'reactstrap';

// rct card box
import { RctCard, RctCardContent } from 'Components/RctCard';

//Actions
import { deleteClient } from "Actions";

function TabContainer({ children, dir }) {
   return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
         {children}
      </Typography>
   );
}

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { NotificationManager } from 'react-notifications';
import Avatar from '@material-ui/core/Avatar';
import { format, parseISO  } from 'date-fns';
// api
import api from 'Api';

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
// intl messages
import IntlMessages from 'Util/IntlMessages';
// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import InputAdornment from '@material-ui/core/InputAdornment';

import { TableCell } from '@material-ui/core';
import UpdateConfirmationDialog from 'Components/DeleteConfirmationDialog/Rrhh/UpdateConfirmationDialog';

const oldRender = TableCell.render

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

class InstalacionesManagement extends Component {
    state = {
        loading: true,
        value: 0,
        favClients: [],
        recentClients: null,
        data:null,
        instalacion:[],
        isUpdated:false,
        fields: {
            checked: false,
            instalacion:"",
            zona:0
        }
    }
    
    componentWillMount(){
        const { location, history} = this.props;
        axios.get('/checkloggin', {}).then((response) => {
          if(!response.data){
              if (localStorage.getItem("user_id") !== null) {
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('name');
                    localStorage.removeItem('email');
                    localStorage.removeItem('rol');
                    history.push('/signin');
                    NotificationManager.error('Â¡Se ha perdido la sesiÃ³n!');
                }
          } else {
              let rol = response.data.rol;
              if(rol != 'administrador' ){
                    history.push('/session/404');
              }
              this.Init();
          }
        }, (error) => {
            this.setState({ loading: false });
            console.log(error);

        });
    }
    
    Init(){

        axios.get(`/instalaciones`)
            .then(
                (res) => {
                    this.setState({loading:false, instalacion: res.data});
                })
            .catch(error => {
               NotificationManager.error(error.message);
               this.setState({ loading: false });
         })
    }

   deleteInstalacion(e, instalacion,zona){
        for(let i=0;i<this.state.instalacion.length;i++){
            if(this.state.instalacion[i].instalacion == instalacion && this.state.instalacion[i].zona == zona){
                 this.setState((prevState) => ({
                    instalacion: [...prevState.instalacion.slice(0,i), ...prevState.instalacion.slice(i+1)]
                }));
                  return false;
            }
        }

   }
 
   
   updateInstalacionTemp(e){

         const { fields, instalacion } = this.state;  
         
         if(fields.instalacion == "" || fields.zona == ""){
            NotificationManager.error("Instalación y zona no pueden ser vaciós");
            return false;
         }
         
          if(this.state.instalacion && this.state.instalacion.length > 0){

            for(let i=0;i<this.state.instalacion.length;i++){
                if(fields.instalacion.toUpperCase() == this.state.instalacion[i].instalacion && fields.zona.toUpperCase() == this.state.instalacion[i].zona){
                    NotificationManager.error("Instalación y Zona ya existen");
                    return false;
                }
             }
            }   
          
            let newInstalacion =  {"instalacion": fields.instalacion.toUpperCase(), "zona": fields.zona.toUpperCase()};
            
            let instalacionesArray = instalacion;
            instalacionesArray.push(newInstalacion);
            instalacionesArray = instalacionesArray.sort(function(a, b){
                if(a.instalacion < b.instalacion) { return -1; }
                if(a.instalacion > b.instalacion) { return 1; }
                return 0;
            })

            this.setState({
               instalacion:instalacionesArray
            });

            this.setState({
               fields: {
                    ...this.state.fields,
                    ["instalacion"]: "",
                    ["zona"]: 0
               }
            });

        e.preventDefault();
   }
   
   updateInstalacion(e){
        const { instalacion } = this.state;
        this.refs.updateConfirmationInstalacionesDialog.close();
        this.setState({ loading: true });

        axios.put(`/instalacion`, {data : instalacion})
            .then(
                (res) => {
                    this.setState({  loading: false });
                    NotificationManager.success(res.data.message);
                })
            .catch((error) => {
            this.setState({ loading: false });
            if (error.response) {
                NotificationManager.error(error.response.data.message);
              } else if (error.request) {
                NotificationManager.error(error.request);
              } else {
                NotificationManager.error(error.message);
              }
             });
        e.preventDefault();
        
    }

   openConfirmationInstalacion(e){
       this.refs.updateConfirmationInstalacionesDialog.open();
   }
   
   /**
    * On Change Add New User Details
    */
   onChangeDetails(key, value) {
        this.setState({
             fields: {
                     ...this.state.fields,
                     [key]: value
             }
        });
   }

    render(){
        
        const { theme, clientsData } = this.props;
        const { loading, fields, instalacion,zona } = this.state;

        return ( 
            <div className="client-tab-wrap mb-4 Tab-wrap">
                    <Helmet>
                            <title>{process.env.MIX_APP_NAME} | Gestión de Instalaciones</title>
                            <meta name="description" content="Gestión de Instalaciones" />
                    </Helmet>
                    <PageTitleBar
                            title={<IntlMessages id="sidebar.instalaciones.management" />}
                            match={this.props.match}
                    />
                    <AppBar position="static" color="default">
                        <Tabs
                           value={this.state.value}
                           onChange={this.handleChange}
                           indicatorColor="primary"
                           textColor="primary"
                        >
                           <Tab label="Instalaciones" />
                         
                        </Tabs>
                     </AppBar>
                     <div>
                   
                        
                       </div> 
                       <div className="card mb-0 transaction-box">
                     <TabContainer dir={theme.direction}>
                        <div className="p-sm-20 pt-sm-30 p-10 pt-15 border-top">
                        <div>
                        <List>
                        <ListItem button>
                           <Form inline>
                                <FormGroup className="mb-10 mr-sm-10 mb-sm-0">
                                        <Label for="instalacion" className="mr-sm-10">Instalacion</Label>
                                        <In autoComplete="off" type="text" name="instalacion" placeholder=""
                                        value={fields.instalacion}
                                        onChange={(e) => this.onChangeDetails('instalacion', e.target.value)}
                                        />
                                </FormGroup>
                                <FormGroup className="mb-10 mr-sm-10 mb-sm-0">
                                        <Label for="zona" className="mr-sm-10">Zona</Label>
                                         <Select onChange={(e) => this.onChangeDetails('zona', e.target.value)} name="zona" value={fields.zona}
                                        >     
                                                <MenuItem value='0'>Seleccione</MenuItem>
                                                <MenuItem value='PUERTO MONTT'>PUERTO MONTT</MenuItem>
                                                <MenuItem value='PUERTO AYSEN'>PUERTO AYSEN</MenuItem>
                                                <MenuItem value='CHILOE'>CHILOÉ</MenuItem>
                                        </Select>       
                                </FormGroup>
                                 <Tooltip title="Agregar" >
                                <Fab onClick={(e) => this.updateInstalacionTemp(e)} size="small" variant="circular" className="btn-primary text-white mr-15 ml-2 mb-5" aria-label="add">+</Fab>
                                 </Tooltip>
                        </Form>
                        </ListItem>
                        <FormGroup className="m-1 mb-2" row>
                            <Button variant="contained" color="primary" className="text-white" onClick={(e) => this.openConfirmationInstalacion(e)}>Actualizar</Button>
                        </FormGroup>
                            {instalacion && instalacion.map((b, key) => (
                           <ListItem button key={key}>
                              <ListItemText primary={b.instalacion} secondary={"Zona: "+b.zona} />
                              <ListItemSecondaryAction>
                                  <Tooltip title="Eliminar" >
                                        <IconButton  onClick={(e) => this.deleteInstalacion(e,b.instalacion,b.zona)} className=""  aria-label="Eliminar" style={{padding: 0}}>
                                          <span edge="start" aria-label="Eliminar" className="material-icons" style={{fontSize: '20px'}}>delete</span>
                                        </IconButton>
                                    </Tooltip>
                              </ListItemSecondaryAction>
                           </ListItem>
                            ))}
                           <ListItem button>
                           <Form inline>
                                <FormGroup className="mb-10 mr-sm-10 mb-sm-0">
                                        <Label for="instalacion" className="mr-sm-10">Instalacion</Label>
                                        <In autoComplete="off" type="text" name="instalacion" placeholder=""
                                        value={fields.instalacion}
                                        onChange={(e) => this.onChangeDetails('instalacion', e.target.value)}
                                        />
                                </FormGroup>
                                <FormGroup className="mb-10 mr-sm-10 mb-sm-0">
                                        <Label for="zona" className="mr-sm-10">Zona</Label>
                                        <Select onChange={(e) => this.onChangeDetails('zona', e.target.value)} name="zona" value={fields.zona}
                                        >     
                                                <MenuItem value='0'>Seleccione</MenuItem>
                                                <MenuItem value='PUERTO MONTT'>PUERTO MONTT</MenuItem>
                                                <MenuItem value='PUERTO AYSEN'>PUERTO AYSEN</MenuItem>
                                                <MenuItem value='CHILOE'>CHILOÉ</MenuItem>
                                        </Select>
                                </FormGroup>
                                 <Tooltip title="Agregar" >
                                <Fab onClick={(e) => this.updateInstalacionTemp(e)} size="small" variant="circular" className="btn-primary text-white mr-15 ml-2 mb-5" aria-label="add">+</Fab>
                                 </Tooltip>
                        </Form>
                        </ListItem>
                     </List>
                     <FormGroup className="m-1" row>
                            <Button variant="contained" color="primary" className="text-white" onClick={(e) => this.openConfirmationInstalacion(e)}>Actualizar</Button>
                        </FormGroup>
                        </div>
                       </div>
                        </TabContainer>
                       </div>   
                        <UpdateConfirmationDialog
                            ref="updateConfirmationInstalacionesDialog"
                            title="¿Seguro que quieres actualizar los datos?"
                            message="Instalaciones eliminadas, dejarán a usuarios sin instalación."
                            onConfirm={(e) => this.updateInstalacion(e)}
                        /> 
                    { loading &&
                    <RctSectionLoader />
                    }    
            </div>
        );
    }
}

export default (withStyles(null, { withTheme: true })(InstalacionesManagement));