    /**
 * Document Management Page
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {validate as Validate, clean, format as Format, getCheckDigit} from 'rut.js';

import {
	Pagination,
	PaginationItem,
	PaginationLink,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge
} from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { NotificationManager } from 'react-notifications';
import Avatar from '@material-ui/core/Avatar';
import { format, parseISO  } from 'date-fns';
// api
import api from 'Api';

// delete confirmation dialog
import DeleteConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import DeleteManyConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteManyConfirmationDialog';
import ActivateManyConfirmationDialog from 'Components/DeleteConfirmationDialog/ActivateManyConfirmationDialog';
import DeActivateManyConfirmationDialog from 'Components/DeleteConfirmationDialog/DeActivateManyConfirmationDialog';

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

// add new document form
import AddNewControlForm from './AddNewControlForm';

// update document form
import UpdateControlForm from './UpdateControlForm';

import { TableCell } from '@material-ui/core'

const oldRender = TableCell.render

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

export default class Control extends React.Component {
        
        state = {
		all: false,
		Controls: null, // initial document data
		selectedControl: null, // selected document to perform operations
		loading: true, // loading activity
		addNewControlModal: false,
                control: null,// add new document form modal
                selectedDate: new Date(),
		addNewControlDetail: {
			id: '',
			nom_per: '',
			rut: '',
			pate: '',
			h_in: new Date().toJSON(),
                        h_sal: new Date().toJSON(),
                        instalacion: '',
                        guardia:'',
                        entre: '',
                        tipo_con: '',
                        guia_des: '',
                        sello: '',
                        espe: '',
                        canda: '',
                        interno: 1,
                        rut_chofer: '',
                        nombre_naviera:'',
                        tipo_movimiento:'',
			checked: false,
                        
		},
		openViewControlDialog: false, // view document dialog box
		editControl: null,
		allSelected: false,
                validate: {
                    nameState : '',
                    emailState: '',
                    rutState : '',
                    rutChoferState : ''
                  },
                maxDate : new Date(),
                maxDateNew : new Date().setFullYear(new Date().getFullYear() + 1),
                minDateNew: new Date(new Date().getFullYear() - 4, new Date().getMonth(), new Date().getDate())
	}
        
        componentWillMount(){

            const { location, history} = this.props;
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rol');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {
                  
                  let rol = response.data.rol;
                    if(rol != 'administrador' && rol != 'supervisor'  && rol != 'empleado' ){
                          history.push('/session/404');
                    }
                  
                  this.Init();
              }
            }, (error) => {
                console.log(error);
                
            });
        }
        
        Init(){
            
            const { selectedDate } = this.state;

            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();

            axios.get(`/control/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data.controls){
                            var localArray = [];
                            localArray.push(res.data.controls[i].rut);
                            localArray.push(res.data.controls[i].interno);
                            localArray.push(res.data.controls[i].tipo_con);
                            localArray.push(res.data.controls[i].nom_per);
                            localArray.push(res.data.controls[i].h_in);
                            localArray.push(res.data.controls[i].h_sal);
                            localArray.push(res.data.controls[i].instalacion);
                            localArray.push(res.data.controls[i].name);
                            localArray.push(res.data.controls[i].rut_chofer);
                            localArray.push(res.data.controls[i].nombre_naviera);
                            localArray.push(res.data.controls[i].pate);
                            localArray.push(res.data.controls[i].tipo_movimiento);
                            localArray.push(res.data.controls[i].entre);                           
                            localArray.push(res.data.controls[i].guia_des);
                            localArray.push(res.data.controls[i].sello);
                            localArray.push(res.data.controls[i].espe);
                            localArray.push(res.data.controls[i].canda);
                            localArray.push(res.data.controls[i].estado);
                            localArray.push(res.data.controls[i].created_at);
                            localArray.push(res.data.controls[i].updated_at);
                            localArray.push(res.data.controls[i]);
                            result.push(localArray);
                           }

                        this.setState({ control: res.data.controls, loading: false, dataTable: result, rowsSelected: [] });
                    })
                .catch(error => {
                   NotificationManager.error(error.message);
                   this.setState({ loading: false });
             })
          }
          
          handleDateChange = (date) => {
                this.setState({ selectedDate: date, loading: true  });
                var month = (date.getMonth() + 1).toString().padStart(2, "0"); 
                var year = date.getUTCFullYear();
                
                 axios.get(`/control/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data.controls){
                            var localArray = [];
                            localArray.push(res.data.controls[i].rut);
                            localArray.push(res.data.controls[i].interno);
                           localArray.push(res.data.controls[i].tipo_con);
                           localArray.push(res.data.controls[i].nom_per);
                            localArray.push(res.data.controls[i].h_in);
                            localArray.push(res.data.controls[i].h_sal);
                            localArray.push(res.data.controls[i].instalacion);
                            localArray.push(res.data.controls[i].name);
                            localArray.push(res.data.controls[i].rut_chofer);
                            localArray.push(res.data.controls[i].nombre_naviera);
                            localArray.push(res.data.controls[i].pate);
                            localArray.push(res.data.controls[i].tipo_movimiento);
                            localArray.push(res.data.controls[i].entre);                           
                            localArray.push(res.data.controls[i].guia_des);
                            localArray.push(res.data.controls[i].sello);
                            localArray.push(res.data.controls[i].espe);
                            localArray.push(res.data.controls[i].canda);
                            localArray.push(res.data.controls[i].estado);
                            localArray.push(res.data.controls[i].created_at);
                            localArray.push(res.data.controls[i].updated_at);
                            localArray.push(res.data.controls[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading: false, dataTable: result, control: res.data.controls, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
          };
          
          /**
	 * Open Add New Document Modal
	 */
	opnAddNewControlModal(e) {
           
            e.preventDefault();

            this.setState({
                    addNewControlDetail: {
                            ...this.state.addNewControlDetail,
                            ["h_in"]: new Date().toJSON(),
                            ["h_sal"]: new Date().toJSON(),
                    }
            });

            this.setState({ addNewControlModal: true,     
                validate: {
                    nameState : '',
                    emailState: '',
                    rutState : '',
                    rutChoferState : ''
                  } });
	}

	/**
	 * On Reload
	 */
        onReload(e) {
            
            const { selectedDate } = this.state;
             
            this.setState({ loading: true });
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            
            axios.get(`/control/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data.controls){
                            var localArray = [];
                            localArray.push(res.data.controls[i].rut);
                            localArray.push(res.data.controls[i].interno);
                           localArray.push(res.data.controls[i].tipo_con);
                           localArray.push(res.data.controls[i].nom_per);
                            localArray.push(res.data.controls[i].h_in);
                            localArray.push(res.data.controls[i].h_sal);
                            localArray.push(res.data.controls[i].instalacion);
                            localArray.push(res.data.controls[i].name);
                            localArray.push(res.data.controls[i].rut_chofer);
                            localArray.push(res.data.controls[i].nombre_naviera);
                            localArray.push(res.data.controls[i].pate);
                            localArray.push(res.data.controls[i].tipo_movimiento);
                            localArray.push(res.data.controls[i].entre);                           
                            localArray.push(res.data.controls[i].guia_des);
                            localArray.push(res.data.controls[i].sello);
                            localArray.push(res.data.controls[i].espe);
                            localArray.push(res.data.controls[i].canda);
                            localArray.push(res.data.controls[i].estado);
                            localArray.push(res.data.controls[i].created_at);
                            localArray.push(res.data.controls[i].updated_at);
                            localArray.push(res.data.controls[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading: false, dataTable: result, control: res.data.controls, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
	}
        
        /**
	 * On Change Add New Document Details
	 */
	onChangeAddNewControlDetails(key, value) {
            
        if(key == 'tipo_con') {
            this.setState({addNewControlDetail: {
                ...this.state.addNewControlDetail,    
                id: '',
                rut: '',
                pate: '',
                h_in: new Date().toJSON(),
                h_sal: new Date().toJSON(),
                instalacion: '',
                guardia:'',
                entre: '',
                tipo_con: value,
                guia_des: '',
                sello: '',
                espe: '',
                canda: '',
                interno: 1,
                rut_chofer: '',
                nombre_naviera:'',
                tipo_movimiento:'',
                checked: false,
            }});
        } else {
            this.setState({
                    addNewControlDetail: {
                            ...this.state.addNewControlDetail,
                            [key]: value
                    }
                });
            }
        }
        
        /**
	 * On Add & Update Document Modal Close
	 */
	onAddUpdateControlModalClose() {
		this.setState({ addNewControlModal: false, editControl: null })
	}
        
        /**
	 * On Update User Details
	 */
	onUpdateControlDetails(key, value) {
		this.setState({
			editControl: {
				...this.state.editControl,
				[key]: value
			}
		});
	}
        
        /**
	 * View Document Detail Hanlder
	 */
	viewControlDetail(data) {
		this.setState({ openViewControlDialog: true, selectedControl: data });
	}

	/**
	 * On Edit Document
	 */
	onEditControl(x, index) {
            if(x.estado == 1){
                x.h_sal = new Date().toJSON();
            }
            this.setState({ addNewControlModal: true, editControl: x, editControlIndex : index, validate: {
                nameState : ''
               }});
	}
        
        onFocusRut(rut,accion){
        if (rut != ''){
                rut = clean(rut);
                if(accion == 'nuevo'){
                this.setState({
			addNewControlDetail: {
				...this.state.addNewControlDetail,
				['rut']: rut
			}
		});
                } else if (accion == 'editar'){
                  this.setState({
			editControl: {
				...this.state.editControl,
				['rut']: rut
			}
                    });                      
                }
            }
        }
        onBlurRut(rut,accion) {
            if (rut != '') {
                if (rut.length > 3) {

                    rut = Format(rut);
                    if(accion == 'nuevo'){
                        this.setState({
                           addNewControlDetail: {
                                       ...this.state.addNewControlDetail,
                                       ['rut']: rut
                               }
                       });
                    }else if (accion == 'editar'){
                        this.setState({
			editControl: {
				...this.state.editControl,
				['rut']: rut
			}
                    });
                    }
                } 
            } 
        }
        
        onFocusRutChofer(rut,accion){
        if (rut != ''){
                rut = clean(rut);
                if(accion == 'nuevo'){
                this.setState({
			addNewControlDetail: {
				...this.state.addNewControlDetail,
				['rut_chofer']: rut
			}
		});
                } else if (accion == 'editar'){
                  this.setState({
			editControl: {
				...this.state.editControl,
				['rut_chofer']: rut
			}
                    });                      
                }
            }
        }
        onBlurRutChofer(rut,accion) {
            if (rut != '') {
                if (rut.length > 3) {

                    rut = Format(rut);
                    if(accion == 'nuevo'){
                        this.setState({
                           addNewControlDetail: {
                                       ...this.state.addNewControlDetail,
                                       ['rut_chofer']: rut
                               }
                       });
                    }else if (accion == 'editar'){
                        this.setState({
			editControl: {
				...this.state.editControl,
				['rut_chofer']: rut
			}
                    });
                    }
                } 
            } 
        }
        
        /**
	 * Add New Document
	 */
	addNewControl(e) {
		const { nom_per } = this.state.addNewControlDetail;
                const { validate, selectedDate } = this.state;
                
                var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                var year = selectedDate.getUTCFullYear();

                this.validateName(nom_per);
                
                if(validate.nameState == 'has-danger'){
                    e.preventDefault();
                    return false;
                }

                let newControl = {
                        ...this.state.addNewControlDetail,
                        id: new Date().getTime()
                }
                
               // newUser.rut = clean(newUser.rut);

                this.setState({ loading: true, addNewControlModal: false });

                axios.post(`/control/`+month+'-'+year, newControl)
                    .then(
                        (res) => {

                            var result = [];
                            for(var i in res.data.controls){
                                var localArray = [];
                                localArray.push(res.data.controls[i].rut);
                                localArray.push(res.data.controls[i].interno);
                                localArray.push(res.data.controls[i].tipo_con);
                                localArray.push(res.data.controls[i].nom_per);
                                localArray.push(res.data.controls[i].h_in);
                                localArray.push(res.data.controls[i].h_sal);
                                localArray.push(res.data.controls[i].instalacion);
                                localArray.push(res.data.controls[i].name);
                                localArray.push(res.data.controls[i].rut_chofer);
                                localArray.push(res.data.controls[i].nombre_naviera);
                                localArray.push(res.data.controls[i].pate);
                                localArray.push(res.data.controls[i].tipo_movimiento);
                                localArray.push(res.data.controls[i].entre);                           
                                localArray.push(res.data.controls[i].guia_des);
                                localArray.push(res.data.controls[i].sello);
                                localArray.push(res.data.controls[i].espe);
                                localArray.push(res.data.controls[i].canda);
                                localArray.push(res.data.controls[i].estado);
                                localArray.push(res.data.controls[i].created_at);
                                localArray.push(res.data.controls[i].updated_at);
                                localArray.push(res.data.controls[i]);
                            result.push(localArray);
                            }

                            this.setState({addNewControlDetail: {
                                    id: '',
                                    nom_per: '',
                                    rut: '',
                                    pate: '',
                                    h_in: new Date().toJSON(),
                                    h_sal: new Date().toJSON(),
                                    instalacion: '',
                                    guardia : '',
                                    entre: '',
                                    interno: 1,
                                    rut_chofer: '',
                                    tipo_con: '',
                                    guia_des: '',
                                    sello: '',
                                    espe: '',
                                    canda: '',
                                    nombre_naviera:'',
                                    tipo_movimiento:'',
                                    checked: false,
                            }});

                            this.setState({ control: res.data.controls, loading: false,loading: false, dataTable: result, rowsSelected: [] });
                            NotificationManager.success(res.data.message);

                        })
                    .catch((error) => {

                        this.setState({ loading: false });
                        if (error.response) {
                            if(Array.isArray(error.response.data.message)){
                                for(let i=0;i<error.response.data.message.length;i++){
                                    NotificationManager.error(error.response.data.message[i]);
                                }
                            } else {
                                NotificationManager.error(error.response.data.message);
                            }
                          } else if (error.request) {
                            NotificationManager.error(error.request);
                          } else {
                            NotificationManager.error(error.message);
                          }
                     })

            e.preventDefault();
	}
        
        /**
	 * On Delete
	 */
	onDelete(data) {
		this.refs.deleteConfirmationDialog.open();
		this.setState({ selectedControl: data });
	}
        
        /**
	 * Update Document
	 */
	updateControl(e) {
            
		const { editControl, editControlIndex, validate, selectedDate } = this.state;
                
                var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                var year = selectedDate.getUTCFullYear();

                this.validateName(editControl.nom_per);
                
                if(validate.nameState == 'has-danger'){
                    e.preventDefault();
                    return false;
                }

		let control = this.state.control;
		control[editControlIndex] = editControl;
		this.setState({ loading: true, addNewControlModal: false });
                
                    axios.put(`/control/`+month+'-'+year, control[editControlIndex])
                        .then(
                            (res) => {
                                
                                var result = [];
                                for(var i in res.data.controls){
                                    var localArray = [];
                                    localArray.push(res.data.controls[i].rut);
                                    localArray.push(res.data.controls[i].interno);
                           localArray.push(res.data.controls[i].tipo_con);
                           localArray.push(res.data.controls[i].nom_per);
                            localArray.push(res.data.controls[i].h_in);
                            localArray.push(res.data.controls[i].h_sal);
                            localArray.push(res.data.controls[i].instalacion);
                            localArray.push(res.data.controls[i].name);
                            localArray.push(res.data.controls[i].rut_chofer);
                            localArray.push(res.data.controls[i].nombre_naviera);
                            localArray.push(res.data.controls[i].pate);
                            localArray.push(res.data.controls[i].tipo_movimiento);
                            localArray.push(res.data.controls[i].entre);                           
                            localArray.push(res.data.controls[i].guia_des);
                            localArray.push(res.data.controls[i].sello);
                            localArray.push(res.data.controls[i].espe);
                            localArray.push(res.data.controls[i].canda);
                            localArray.push(res.data.controls[i].estado);
                            localArray.push(res.data.controls[i].created_at);
                            localArray.push(res.data.controls[i].updated_at);
                            localArray.push(res.data.controls[i]);
                            result.push(localArray);
                                }
     
                                this.setState({ control: res.data.controls, loading: false , dataTable: result, rowsSelected: [], editControl : null });
                                NotificationManager.success(res.data.message);
   
                            })
                        .catch((error) => {

                        this.setState({ loading: false, editControl : null });
                        if (error.response) {
                            NotificationManager.error(error.response.data.message);
                          } else if (error.request) {
                            NotificationManager.error(error.request);
                          } else {
                            NotificationManager.error(error.message);
                          }
                         })
                    e.preventDefault();
              
	}
        
        /**
	 * Delete Document Permanently
	 */
	deleteControlPermanently(e) {
            const { selectedControl, selectedDate } = this.state;
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            this.refs.deleteConfirmationDialog.close();
            this.setState({ loading: true });

            axios.delete(`/control/`+month+'-'+year, {data : selectedControl})
                    .then(
                        (res) => {

                            var result = [];
                            for(var i in res.data.controls){
                                var localArray = [];
                                localArray.push(res.data.controls[i].rut);
                                localArray.push(res.data.controls[i].interno);
                                localArray.push(res.data.controls[i].tipo_con);
                                localArray.push(res.data.controls[i].nom_per);
                                localArray.push(res.data.controls[i].h_in);
                                localArray.push(res.data.controls[i].h_sal);
                                localArray.push(res.data.controls[i].instalacion);
                                localArray.push(res.data.controls[i].name);
                                localArray.push(res.data.controls[i].rut_chofer);
                                localArray.push(res.data.controls[i].nombre_naviera);
                                localArray.push(res.data.controls[i].pate);
                                localArray.push(res.data.controls[i].tipo_movimiento);
                                localArray.push(res.data.controls[i].entre);                           
                                localArray.push(res.data.controls[i].guia_des);
                                localArray.push(res.data.controls[i].sello);
                                localArray.push(res.data.controls[i].espe);
                                localArray.push(res.data.controls[i].canda);
                                localArray.push(res.data.controls[i].estado);
                                localArray.push(res.data.controls[i].created_at);
                                localArray.push(res.data.controls[i].updated_at);
                                localArray.push(res.data.controls[i]);
                                result.push(localArray);
                            }

                            this.setState({ control: res.data.controls, loading: false,loading: false, dataTable: result, rowsSelected: [] });
                            NotificationManager.success(res.data.message);

                        })
                    .catch((error) => {
                    this.setState({ loading: false });
                        /*console.log(error); console.log(error.response);
                       NotificationManager.error(error.message);
                       this.setState({ loading: false });*/
                    if (error.response) {
                        // La respuesta fue hecha y el servidor respondió con un código de estado
                        // que esta fuera del rango de 2xx
                        //console.log(error.response.data);
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        // La petición fue hecha pero no se recibió respuesta
                        // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                        // http.ClientRequest en node.js
                        NotificationManager.error(error.request);
                      } else {
                        // Algo paso al preparar la petición que lanzo un Error
                        NotificationManager.error(error.message);
                      }
                    })
                e.preventDefault();
            
	}
        
        /**
	 * Delete Document Permanently
	 */
	deleteManyControlPermanently(e) {

            const { allSelected, selectedDate } = this.state;
            
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            
            this.refs.deleteManyConfirmationDialog.close();
            this.setState({ loading: true });

            axios.delete(`/control-many/`+month+'-'+year, {data : {'ids' : allSelected}})
                .then(
                    (res) => {
                        var result = [];
                        for(var i in res.data.controls){
                            var localArray = [];
                                localArray.push(res.data.controls[i].rut);
                                localArray.push(res.data.controls[i].interno);
                                localArray.push(res.data.controls[i].tipo_con);
                                localArray.push(res.data.controls[i].nom_per);
                                localArray.push(res.data.controls[i].h_in);
                                localArray.push(res.data.controls[i].h_sal);
                                localArray.push(res.data.controls[i].instalacion);
                                localArray.push(res.data.controls[i].name);
                                localArray.push(res.data.controls[i].rut_chofer);
                                localArray.push(res.data.controls[i].nombre_naviera);
                                localArray.push(res.data.controls[i].pate);
                                localArray.push(res.data.controls[i].tipo_movimiento);
                                localArray.push(res.data.controls[i].entre);                           
                                localArray.push(res.data.controls[i].guia_des);
                                localArray.push(res.data.controls[i].sello);
                                localArray.push(res.data.controls[i].espe);
                                localArray.push(res.data.controls[i].canda);
                                localArray.push(res.data.controls[i].estado);
                                localArray.push(res.data.controls[i].created_at);
                                localArray.push(res.data.controls[i].updated_at);
                                localArray.push(res.data.controls[i]);
                            result.push(localArray);
                           }
                        this.setState({ control: res.data.controls,  loading: false, dataTable: result, rowsSelected: [] });
                        NotificationManager.success(res.data.message);
                    })
                .catch(error => {
                    this.setState({ loading: false });

                   if (error.response) {
                       if(Array.isArray(error.response.data.message)){
                           for(let i=0;i<error.response.data.message.length;i++){
                               NotificationManager.error(error.response.data.message[i]);
                           }
                       } else {
                           NotificationManager.error(error.response.data.message);
                       }
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
            
	}
        
        /**
	 * Deactivate Document Permanently
	 */
	deActivateManyControlPermanently(e) {

            const { allSelected, selectedDate } = this.state;
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            this.refs.deActivateManyConfirmationDialog.close();
            this.setState({ loading: true });

            axios.put(`/control-many-deactivate/`+month+'-'+year, {'ids' : allSelected})
                .then(
                    (res) => {
                        var result = [];
                        for(var i in res.data.controls){
                            var localArray = [];
                                localArray.push(res.data.controls[i].rut);
                                localArray.push(res.data.controls[i].interno);
                                localArray.push(res.data.controls[i].tipo_con);
                                localArray.push(res.data.controls[i].nom_per);
                                localArray.push(res.data.controls[i].h_in);
                                localArray.push(res.data.controls[i].h_sal);
                                localArray.push(res.data.controls[i].instalacion);
                                localArray.push(res.data.controls[i].name);
                                localArray.push(res.data.controls[i].rut_chofer);
                                localArray.push(res.data.controls[i].nombre_naviera);
                                localArray.push(res.data.controls[i].pate);
                                localArray.push(res.data.controls[i].tipo_movimiento);
                                localArray.push(res.data.controls[i].entre);                           
                                localArray.push(res.data.controls[i].guia_des);
                                localArray.push(res.data.controls[i].sello);
                                localArray.push(res.data.controls[i].espe);
                                localArray.push(res.data.controls[i].canda);
                                localArray.push(res.data.controls[i].estado);
                                localArray.push(res.data.controls[i].created_at);
                                localArray.push(res.data.controls[i].updated_at);
                                localArray.push(res.data.controls[i]);
                            result.push(localArray);
                           }
                        this.setState({ control: res.data.controls,  loading: false, dataTable: result, rowsSelected: [] });
                        NotificationManager.success(res.data.message);
                    })
                .catch(error => {
                    this.setState({ loading: false });

                   if (error.response) {
                       if(Array.isArray(error.response.data.message)){
                           for(let i=0;i<error.response.data.message.length;i++){
                               NotificationManager.error(error.response.data.message[i]);
                           }
                       } else {
                           NotificationManager.error(error.response.data.message);
                       }
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
            
	}
        
        /**
	 * Activate Document Permanently
	 */
	activateManyControlPermanently(e) {

            const { allSelected, selectedDate } = this.state;
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            this.refs.activateManyConfirmationDialog.close();
            this.setState({ loading: true });

            axios.put(`/control-many-activate/`+month+'-'+year, {'ids' : allSelected})
                .then(
                    (res) => {
                        var result = [];
                        for(var i in res.data.controls){
                            var localArray = [];
                                localArray.push(res.data.controls[i].rut);
                                localArray.push(res.data.controls[i].interno);
                                localArray.push(res.data.controls[i].tipo_con);
                                localArray.push(res.data.controls[i].nom_per);
                                localArray.push(res.data.controls[i].h_in);
                                localArray.push(res.data.controls[i].h_sal);
                                localArray.push(res.data.controls[i].instalacion);
                                localArray.push(res.data.controls[i].name);
                                localArray.push(res.data.controls[i].rut_chofer);
                                localArray.push(res.data.controls[i].nombre_naviera);
                                localArray.push(res.data.controls[i].pate);
                                localArray.push(res.data.controls[i].tipo_movimiento);
                                localArray.push(res.data.controls[i].entre);                           
                                localArray.push(res.data.controls[i].guia_des);
                                localArray.push(res.data.controls[i].sello);
                                localArray.push(res.data.controls[i].espe);
                                localArray.push(res.data.controls[i].canda);
                                localArray.push(res.data.controls[i].estado);
                                localArray.push(res.data.controls[i].created_at);
                                localArray.push(res.data.controls[i].updated_at);
                                localArray.push(res.data.controls[i]);
                            result.push(localArray);
                           }
                        this.setState({ control: res.data.controls,  loading: false, dataTable: result, rowsSelected: [] });
                        NotificationManager.success(res.data.message);
                    })
                .catch(error => {
                    this.setState({ loading: false });

                   if (error.response) {
                       if(Array.isArray(error.response.data.message)){
                           for(let i=0;i<error.response.data.message.length;i++){
                               NotificationManager.error(error.response.data.message[i]);
                           }
                       } else {
                           NotificationManager.error(error.response.data.message);
                       }
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
            
	}
      
        validateName(nom_per){
            const { validate } = this.state;
            if(nom_per == "" ){
                validate.nameState = 'has-danger';
            } else {
                validate.nameState = 'has-success';
            }
            this.setState({ validate });
        }
        
        render() {
            
            const { control, loading, editControl, allSelected, selectedControl, dataTable, editControlIndex, selectedDate, maxDateNew, minDateNew} = this.state;

            const columns = [ 
                            {"name":"RUT",
                             "label":"RUT",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      },
                                    }},
                             {"name":"Interno",
                             "label":"Interno",
                                options: {
                                    filter: true,
                                    display : false,
                                    viewColumns:true,
                                    customBodyRender: (value, tableMeta) => {
                                        if(value == 1) {
                                            return 'Interno'
                                        } else if(value == 0) {
                                            return 'Externo';
                                        } else {
                                            return '-';
                                        }
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Control",
                             "label":"Control",
                                options: {
                                    filter: true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Nombre",
                             "label":"Nombre",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                          
                            {"name": "Entrada","label":"Entrada",
                                options: {
                                    filter:false,
                                    options: { sortDirection: 'desc' },
                                    customBodyRender: (value) => {
                                        return format(parseISO(value), 'dd-MM-yyyy H:mm')
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                         if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                              },},
                          {"name": "Salida","label":"Salida",
                                options: {
                                    filter:false,
                                    customBodyRender: (value, tableMeta) => {
                                        if(tableMeta.rowData[17] == 2) { //estado
                                            return format(parseISO(value), 'dd-MM-yyyy H:mm')
                                        } else {
                                            return '-';
                                        }
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                       if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                              },},
                          {"name": "Instalación","label":"Instalación",
                                options: {
                                    filter: true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                              },},
                          {"name": "Guardia","label":"Guardia",
                                options: { 
                                    filter:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                              },},
                          {"name":"RUT Chofer",
                             "label":"RUT Chofer",
                                options: {
                                    filter: false,
                                    display : false,
                                    viewColumns:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      },
                                    }},
                            {"name":"Nom. Naviera",
                             "label":"Nom. Naviera",
                                options: {
                                    filter: false,
                                    display : false,
                                    viewColumns:true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                          {"name":"Patente",
                             "label":"Patente",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Tipo de Movimiento",
                             "label":"Tipo de Movimiento",
                                options: {
                                    filter: false,
                                    display : false,
                                    viewColumns:true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Entrevistado",
                             "label":"Entrevistado",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Guia de Despacho",
                             "label":"Guia de Despacho",
                                options: {
                                    filter: false,
                                    display : false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      },
                                    customBodyRender: (value) => {
                                        if(value == 0){
                                            return '-';
                                        } else {
                                           return value; 
                                        }
                                    }
                                }},
                             {"name":"Sello",
                             "label":"Sello",
                                options: {
                                    filter: false,
                                    display : false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      },
                                    customBodyRender: (value) => {
                                        if(value == 0){
                                            return '-';
                                        } else {
                                           return value; 
                                        }
                                    }
                                }},
                             {"name":"Especies",
                             "label":"Especies",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                             {"name":"Candado",
                             "label":"Candado",
                                options: {
                                    filter: false,
                                    display : false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      },
                                    customBodyRender: (value) => {
                                        if(value == 0){
                                            return '-';
                                        } else {
                                           return value; 
                                        }
                                    }
                                }},
                            {"name":"Estado",
                             "label":"Estado",
                                options: {
                                    filter: true,
                                    display : false,
                                    viewColumns:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Registro ingreso (real)",
                             "label":"Registro ingreso (real)",
                                options: {
                                    filter: false,
                                    display : false,
                                    viewColumns:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    customBodyRender: (value) => {
                                        return format(parseISO(value), 'dd-MM-yyyy H:mm')
                                    },
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Registro salida (real)",
                             "label":"Registro salida (real)",
                                options: {
                                    filter: false,
                                    display : false,
                                    viewColumns:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    customBodyRender: (value) => {
                                        return format(parseISO(value), 'dd-MM-yyyy H:mm')
                                    },
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Acciones","label":"Acciones",
                                
                                options: {
                                    filter: false,
                                    sort: false,
                                    download: false,
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.control != null && this.state.control[rowIndex] != null && this.state.control[rowIndex].estado == 1){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      },
                                    customBodyRender: (value, tableMeta) => { 
                                        if(this.state.control != null && this.state.control[tableMeta.rowIndex] != null && this.state.control[tableMeta.rowIndex].estado == 1){
                                            
                                            return (
                                                    <div className="list-action">
                                                        <Tooltip title="Ver">
                                                            <button type="button" className="rct-link-btn text-white" onClick={() => this.viewControlDetail(value)}><i className="ti-eye"></i></button>
                                                        </Tooltip>
                                                        <Tooltip title="Editar">
                                                            <button type="button" className="rct-link-btn text-white" onClick={() => this.onEditControl(value,tableMeta.rowIndex)}><i className="ti-pencil"></i></button>
                                                        </Tooltip>
                                                        <Tooltip title="Eliminar">
                                                            <button type="button" className="rct-link-btn text-white" onClick={() => this.onDelete(value)}><i className="ti-close"></i></button>
                                                        </Tooltip>
                                                    </div>
                                                );
                                        } else {
                                        
                                            return (
                                                    <div className="list-action">
                                                        <Tooltip title="Ver">
                                                            <button type="button" className="rct-link-btn" onClick={() => this.viewControlDetail(value)}><i className="ti-eye"></i></button>
                                                        </Tooltip>
                                                        <Tooltip title="Editar">
                                                            <button type="button" className="rct-link-btn" onClick={() => this.onEditControl(value,tableMeta.rowIndex)}><i className="ti-pencil"></i></button>
                                                        </Tooltip>
                                                        <Tooltip title="Eliminar">    
                                                            <button type="button" className="rct-link-btn" onClick={() => this.onDelete(value)}><i className="ti-close"></i></button>
                                                        </Tooltip>
                                                    </div>
                                                );
                                            }
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                              }}];
                    const data = dataTable;                    
                    const options = {
			filterType: 'dropdown',
                       
                        setRowProps: (row, dataIndex, rowIndex) => {
                            if(row[17] == 1){
                                return {
                                   style: { background: /*'rgb(255, 145, 110)'*/ '#546e7a'},
                                };
                            }
                          },
                        tableBodyMaxHeight:'500px',
                        tableBodyHeight:'500px',
                        download: true,
                        onDownload: (buildHead, buildBody, columns, data) => {
                                buildHead = () => {
                                   var col = "";
                                   for(let i=0;i<columns.length;i++){
                                       if(columns[i].download == true){
                                            if((i+2) < columns.length){ //+2 por omitir columnas actions
                                                col += columns[i].name+";";
                                            } else {
                                                col += columns[i].name+"\n";
                                            }
                                        }
                                   } 
                                   
                                   return col;
                                   
                                }
                                buildBody = () => { 
                                    
                                    var values = "";
                                    for(let i=0;i<data.length;i++){
                                        
                                        var dato = data[i].data[(data[i].data.length-1)];
                                        
                                        values += dato.rut+";";
                                        if(dato.interno == 1) {
                                            values += "Interno;";  
                                        } else if(dato.interno == 0) {
                                            values += "Externo;";  
                                        }else{
                                            values += "-;";  
                                        }
                                        values += dato.tipo_con+";";
                                        values += dato.nom_per+";";
                                        values += format(parseISO(dato.h_in), 'dd-MM-yyyy H:mm')+";"; 
                                        if(dato.estado == 2){
                                            values += format(parseISO(dato.h_sal), 'dd-MM-yyyy H:mm')+";"; 
                                        } else {
                                            values += "-;";  
                                        }
                                        values += dato.instalacion+";";
                                        values += dato.name+";";
                                        values += dato.rut_chofer+";";
                                        values += dato.nombre_naviera+";";
                                        values += dato.pate+";";
                                        values += dato.tipo_movimiento+";";
                                        values += dato.entre+";";
                                        if(dato.guia_des == 0) {
                                            values += "-;";  
                                        } else {
                                            values += dato.guia_des+";";  
                                        }
                                        if(dato.sello == 0) {
                                            values += "-;";  
                                        } else {
                                            values += dato.sello+";";  
                                        }
                                        values += dato.espe+";";
                                        if(dato.canda == 0) {
                                            values += "-;";  
                                        } else {
                                            values += dato.canda+";";  
                                        }
                                        values += dato.estado+";"; 
                                        values += format(parseISO(dato.created_at), 'dd-MM-yyyy H:mm')+";"; 
                                        if(dato.estado == 2){
                                            values += format(parseISO(dato.updated_at), 'dd-MM-yyyy H:mm')+"\n"; 
                                        } else {
                                            values += "-\n";
                                        }
                                    }
                                    return values
                                }
                                return buildHead() + buildBody();
                              },
                        print: false,
                        downloadOptions : {filename: 'sistema_control.csv', separator: ';', filterOptions:{ useDisplayedRowsOnly : true }},
                        textLabels: {
                            body: {
                              noMatch: "Lo sentimos, no se encotraron registros",
                              toolTip: "Ordenar",
                              columnHeaderTooltip: column => `Ordenar por ${column.label}`
                            },
                            pagination: {
                              next: "Siguiente",
                              previous: "Anterior",
                              rowsPerPage: "Fillas por página:",
                              displayRows: "de",
                            },
                            toolbar: {
                              search: "Buscar",
                              downloadCsv: "Descargar CSV",
                              //print: "Print",
                              viewColumns: "Ver Columnas",
                              filterTable: "Filtrar",
                            },
                            filter: {
                              all: "Todas",
                              title: "FILTRAR",
                              reset: "REINICIAR",
                            },
                            viewColumns: {
                              title: "Mostrar Columnas",
                              titleAria: "Mostrar/Ocultar Columnas",
                            },
                            selectedRows: {
                              text: "filas(s) seleccionadas",
                              delete: "Eliminar",
                              deleteAria: "Eliminar Filas Seleccionadas",
                            }
                          },
                          /*sortOrder: {
                                name: 'Entrada',
                                direction: 'desc',
                            },*/
                            onRowsDelete: (rowsDeleted) => {
                                let documents = this.state.documents;
                                let idsToDelete = rowsDeleted.data.map (item => item.dataIndex);
                                let obj = [];
                                for(let i=0;i<idsToDelete.length;i++){
                                    obj.push(documents[idsToDelete[i]].id)
                                }
      
                                this.refs.deleteManyConfirmationDialog.open();
                                this.setState({ allSelected: obj });
                    
                                return false;
                            },
                            rowsSelected: this.state.rowsSelected,
                            onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
                              this.setState({ rowsSelected: rowsSelected });
                            },
                            
                            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                                
                                 const handleClick = (e, accion) => {
                                    let control = this.state.control;
                                    let idsToDelete = selectedRows.data.map (item => item.dataIndex);
                                    let obj = [];
                                    for(let i=0;i<idsToDelete.length;i++){
                                        obj.push(control[idsToDelete[i]].id)
                                    }
                                    if(accion == 'delete'){
                                        this.refs.deleteManyConfirmationDialog.open();
                                    } else if (accion == 'activate') {
                                        this.refs.activateManyConfirmationDialog.open();
                                    } else if (accion == 'deactivate') {
                                        this.refs.deActivateManyConfirmationDialog.open();
                                    }
                                    this.setState({ allSelected: obj });
                               
                                    e.preventDefault();
                                 };

                                return (
                                        <div>
                                                <Tooltip title="Eliminar">
                                                    <IconButton onClick={(e) => handleClick(e,'delete')} className="mr-4"  aria-label="Eliminar">
                                                      <span edge="start" aria-label="Deshabilitar" className="material-icons">delete</span>
                                                    </IconButton>
                                                </Tooltip>
                                        </div>
                                        )
                         },
   
                    };

            return ( <div className="control-management">            
                <Helmet>
                        <title>{process.env.MIX_APP_NAME} | Gestión de Control</title>
                        <meta name="description" content="Gestión de Control" />
                </Helmet>
                <PageTitleBar
                        title={<IntlMessages id="sidebar.controlManagement" />}
                        match={this.props.match}
                />
                <RctCollapsibleCard  fullBlock>
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="#" onClick={(e) => this.onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"></i></a>
                            <a href="#" onClick={(e) => this.opnAddNewControlModal(e)} color="primary" className="btn-sm btn-outline-default mr-10">Crear Nuevo <i className="zmdi zmdi-plus"></i></a>
                            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                <DatePicker
                                        views={['year', 'month']}
                                        label="Selecciona periodo"
                                        autoOk
                                        showTabs={true}
                                        maxDate={maxDateNew}
                                        minDate={minDateNew}
                                        dateformat="MM-YYYY"
                                        value={selectedDate}
                                        onChange={this.handleDateChange}
                                        showmonthyearpicker="true"
                                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <MUIDataTable className="table table-middle table-hover mb-0"
                            title={"Listado de Controles"}
                            data={data}
                            columns={columns}
                            options={options}
                    />
                    { loading &&
                            <RctSectionLoader />
                    }    
                </RctCollapsibleCard>
                <DeleteConfirmationDialog
                            ref="deleteConfirmationDialog"
                            title="¿Seguro que quieres eliminar el registro?"
                            message="Esto lo eliminará permanentemente."
                            onConfirm={(e) => this.deleteControlPermanently(e)}
                    />
                    <DeleteManyConfirmationDialog
                            ref="deleteManyConfirmationDialog"
                            title="¿Seguro que quieres eliminar el/los registro(s) seleccionado(s)?"
                            message="Esto lo(s) eliminará permanentemente."
                            onConfirm={(e) => this.deleteManyControlPermanently(e)}
                    />
                    <ActivateManyConfirmationDialog
                            ref="activateManyConfirmationDialog"
                            title="¿Seguro que quieres habilitar el/los registro(s) seleccionado(s)?"
                            message="Esto lo(s) habilitará permanentemente."
                            onConfirm={(e) => this.activateManyControlPermanently(e)}
                    />
                    <DeActivateManyConfirmationDialog
                            ref="deActivateManyConfirmationDialog"
                            title="¿Seguro que quieres deshabilitar el/los registro(s) seleccionado(s)?"
                            message="Esto lo(s) deshabilitará permanentemente."
                            onConfirm={(e) => this.deActivateManyControlPermanently(e)}
                    />
                    <Modal isOpen={this.state.addNewControlModal} toggle={() => this.onAddUpdateControlModalClose()} backdrop="static" >
                            <ModalHeader toggle={() => this.onAddUpdateControlModalClose()}>
                                    {editControl === null && this.state.addNewControlModal  === true ?
                                            'Crear Nuevo Control' : 'Actualizar Control'
                                    }
                            </ModalHeader>
                            <ModalBody>
                                    {editControl === null ?
                                            <AddNewControlForm
                                                    addNewControlDetails={this.state.addNewControlDetail}
                                                    onChangeAddNewControlDetails={this.onChangeAddNewControlDetails.bind(this)}
                                                    onBlurRut={this.onBlurRut.bind(this)}
                                                    onFocusRut={this.onFocusRut.bind(this)}
                                                    onBlurRutChofer={this.onBlurRutChofer.bind(this)}
                                                    onFocusRutChofer={this.onFocusRutChofer.bind(this)}
                                                    validate={this.state.validate}
                                                    validateName={this.validateName.bind(this)}
                                                    maxDate={this.state.maxDate.setDate(new Date().getDate()+1)}
                                            />
                                            : <UpdateControlForm control={editControl} onUpdateControlDetail={this.onUpdateControlDetails.bind(this)} index={editControlIndex} 
                                                    validate={this.state.validate} 
                                                    onBlurRut={this.onBlurRut.bind(this)}
                                                    onFocusRut={this.onFocusRut.bind(this)}
                                                    onBlurRutChofer={this.onBlurRutChofer.bind(this)}
                                                    onFocusRutChofer={this.onFocusRutChofer.bind(this)}
                                                    maxDate={this.state.maxDate.setDate(new Date().getDate()+1)}
                                            />
                                    }
                            </ModalBody>
                            <ModalFooter>
                                    {editControl === null && this.state.addNewControlModal === true && 
                                            <Fragment> 
                                            <Button variant="contained" className="text-white btn-success" onClick={(e) => this.addNewControl(e)}>Crear</Button>
                                            <Button variant="contained" className="text-white btn-danger" onClick={() => this.onAddUpdateControlModalClose()}>Cancelar</Button>
                                            </Fragment> 
                                    }
                                    {editControl !== null &&  this.state.addNewControlModal === true && editControl.estado != 2 &&
                                          <Fragment> 
                                        <Button variant="contained" color="primary" className="text-white" onClick={(e) => this.updateControl(e)}>Actualizar</Button>
                                        <Button variant="contained" className="text-white btn-danger" onClick={() => this.onAddUpdateControlModalClose()}>Cancelar</Button>
                                        </Fragment> 
                                     } 
                                     {editControl !== null &&  this.state.addNewControlModal === true && editControl.estado == 2 &&
                                          <Fragment> 
                                        <span edge="start" aria-label="Registro Completado">Registro Completado</span>
                                        <Button variant="contained"  color="primary" className="text-white" onClick={() => this.onAddUpdateControlModalClose()}>Cerrar</Button>
                                        </Fragment> 
                                     }
                            </ModalFooter>
                    </Modal>
                    <Dialog
                            onClose={() => this.setState({ openViewControlDialog: false })}
                            open={this.state.openViewControlDialog}
                    >
                            <DialogContent>
                                    {selectedControl !== null &&
                                            <div>
                                                    <div className="clearfix d-flex">
                                                            <div className="media pull-left">
                                                                    <div className="media-body">
                                                                    <p>Tipo control: <span className="fw-bold">{selectedControl.tipo_con}</span></p>
                                                                                <p>Rut: <span className="fw-bold">{Format(selectedControl.rut)}</span></p>
                                                                                <p>Nombre: <span className="fw-bold">{selectedControl.nom_per}</span></p>
                                                                                <p>Fecha ingreso: <span className="fw-bold">{format(parseISO(selectedControl.h_in), 'dd/MM/yyyy H:mm')}</span></p>
                                                                                <p>Fecha Salida: <span className="fw-bold">{selectedControl.estado == 2 ? format(parseISO(selectedControl.h_sal), 'dd/MM/yyyy H:mm') : '-'}</span></p>
                                                                                <p>Instalación: <span className="fw-bold">{selectedControl.instalacion}</span></p>
                                                                                <p>Guardia: <span className="fw-bold">{selectedControl.name}</span></p>
                                                                                <p>RUT Chofer: <span className="fw-bold">{selectedControl.rut_chofer}</span></p>
                                                                                <p>Nombre Naviera: <span className="fw-bold">{selectedControl.nombre_naviera}</span></p>
                                                                                <p>Patente: <span className="fw-bold">{selectedControl.pate}</span></p>
                                                                                <p>Tipo Movimiento: <span className="fw-bold">{selectedControl.tipo_movimiento}</span></p>
                                                                                <p>Entrevistado: <span className="fw-bold">{selectedControl.entre}</span></p>
                                                                                <p>Guia Despacho: <span className="fw-bold">{selectedControl.Guia_des}</span></p>
                                                                                <p>Sello: <span className="fw-bold">{selectedControl.sello}</span></p>
                                                                                <p>Especies: <span className="fw-bold">{selectedControl.espe}</span></p>
                                                                                <p>Candado: <span className="fw-bold">{selectedControl.canda}</span></p>
                                                                                <p>Interno: <span className="fw-bold">{selectedControl.interno == 1 ? 'Interno' :''}{selectedControl.interno == 0 ? 'Externo' : ''}{selectedControl.interno != 1 && selectedControl.interno != 0 ? '-' : ''}</span></p>
                                                                                       
                                                                    </div>
                                                            </div>
                                                    </div>
                                            </div>
                                    }
                            </DialogContent>
                    </Dialog>
            </div>);
                                
        }
}