/**
 * Update User Details Form
 */
import React from 'react';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';

const UpdateDocumentForm = ({ document, onUpdateDocumentDetail, index, validate }) => (
    <Form>
        <FormGroup>
            <Label for="name">Nombre</Label>
            <Input
                type="text"
                name="name"
                id="name"
                value={document.name}
                invalid={validate.nameState === "has-danger"}
                placeholder="Ingrese Nombre"
                onChange={(e) => onUpdateDocumentDetail('name', e.target.value)}
            />
            <FormFeedback>
              Nombre no puede ser vacío o muy corto.
            </FormFeedback>
        </FormGroup>
        <FormGroup>
            <Label className="mb-1" for="requerido">Requerido</Label>
            <FormControl fullWidth>
                <Switch name="requerido" color="primary" id="requerido" checked={(document.requerido === 1 || document.requerido === true) ? true : false } 
                onChange={(e) => onUpdateDocumentDetail('requerido', e.target.checked)} aria-label="requerido" />
              </FormControl>
        </FormGroup>
        <FormGroup>
        <FormGroup>
            <Label for="expirated_at">Días previos de aviso (0 para no notificar)</Label>
            <Input
                type="number"
                name="expirated_at"
                id="expirated_at"
                value={document.expirated_at}
                onChange={(e) => onUpdateDocumentDetail('expirated_at', e.target.value)}
            />
        </FormGroup>
            <Label className="mb-1" for="estado">Estado</Label>
            <FormControl fullWidth>
                <Select name="estado" name="estado" value={document.estado} onChange={(e) => onUpdateDocumentDetail('estado', e.target.value)}
                >
                      <MenuItem value={1}>Habilitado</MenuItem>
                      <MenuItem value={0}>Deshabilitado</MenuItem>
                </Select>
              </FormControl>
        </FormGroup>
    </Form>
);

export default UpdateDocumentForm;
