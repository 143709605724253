/**
 * Update User Details Form
 */
import React, { Component, Fragment } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import {NumericFormat } from 'react-number-format';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Alert } from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import { NotificationManager } from 'react-notifications';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

} from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import {validate as Validate, clean, format as Format, getCheckDigit} from 'rut.js'
import { format, parseISO  } from 'date-fns';
import InstalacionesSelect from 'Components/InstalacionesSelect/InstalacionesSelect';

function TabContainer({ children, dir }) {
   return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
         {children}
      </Typography>
   );
}

class UpdateSeguimientoForm extends Component {
//export default class UpdateLiquidacionForm extends React.Component {
        
    constructor(props) {
        super(props);
    }
    
    state = {
        addNewSeguimientoDetails : null,
        selectedSeguimientoUser: null,
        blockRefresh:false
    }
    
    componentDidMount() {
        this.setState({ 
            addNewSeguimientoDetails: this.props.addNewSeguimientoDetails,
            selectedSeguimientoUser: this.props.selectedSeguimientoUser,
            editSeguimiento : this.props.editSeguimiento,
            valueTab : this.props.valueTab
        });
    }
    componentWillReceiveProps(props){
        if(props.addNewSeguimientoDetails){

            this.setState({ 
                addNewSeguimientoDetails: props.addNewSeguimientoDetails,
                selectedSeguimientoUser: props.selectedSeguimientoUser,
                editSeguimiento : props.editSeguimiento,
                setStatoLoadingProp : props.setStatoLoading,
                valueTab : props.valueTab
            });
        }
    }
 
    /**
        * On Update User Details
        */
       onUpdateSeguimientoDetail(key, value) {
               this.setState({
                       addNewSeguimientoDetails: {
                               ...this.state.addNewSeguimientoDetails,
                               [key]: value
                       }
               });
       }
        
    
        onBlurRut(rut,accion) {
            if (rut != '') {
                if (rut.length > 3) {

                    rut = Format(rut);
                    if(accion == 'nuevo'){
                        this.setState({
                           addNewSeguimientoDetail: {
                                       ...this.state.addNewSeguimientoDetail,
                                       ['rut']: rut
                               }
                       });
                    }else if (accion == 'editar'){
                        this.setState({
			addNewSeguimientoDetails: {
				...this.state.addNewSeguimientoDetails,
				['rut']: rut
			}
                    });
                    }
                } 
            } 
        }
        
         setStatoLoading(t){
            this.state.setStatoLoadingProp(t);
        }
        setBlockRefresh(t){
            this.setState({ blockRefresh : t });
        }

        
         onFocusRut(rut,accion){
        if (rut != ''){
                rut = clean(rut);
                if(accion == 'nuevo'){
                this.setState({
			addNewSeguimientoDetail: {
				...this.state.addNewSeguimientoDetail,
				['rut']: rut
			}
		});
                } else if (accion == 'editar'){
                  this.setState({
			addNewSeguimientoDetails: {
				...this.state.addNewSeguimientoDetails,
				['rut']: rut
			}
                    });                      
                }
            }
        }
        
        handleChangeTab = (event, valueTab) => {
            this.setState({ valueTab });
         };

     render() {
        
        const { theme } = this.props; 
        const { addNewSeguimientoDetails, selectedSeguimientoUser, editSeguimiento, valueTab} = this.state;
        
        return ( 
            <Fragment>            
            <ModalBody>    
            { addNewSeguimientoDetails !== null && selectedSeguimientoUser !== null && 
            <Fragment> 

            <Form>
            <AppBar position="static" color="default">
                        <Tabs
                           value={valueTab}
                           onChange={this.handleChangeTab}
                           indicatorColor="primary"
                           textColor="primary"
                           variant="scrollable"
                           scrollButtons="auto"
                        >
                           <Tab label="Seguimiento" />
                           <Tab label="Crear nuevo" />
                        </Tabs>
                     </AppBar>
                     <div>
                    <SwipeableViews
                
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={valueTab}>
                  <div className="card mb-0 transaction-box">
                     <TabContainer dir={theme.direction}>
                     <div className="p-sm-20 pt-sm-30 p-8 pt-10 border-top">
                        {editSeguimiento.length <= 0 &&
                            <div>
                                <div className="">
                                        <div className="text-center">
                                                <p>Sin información</p>  
                                        </div>
                                </div>
                            </div>
                        }
                        {editSeguimiento.length > 0 &&
                           
                                <TableContainer  style={{maxHeight: 600}} >
                                <Table stickyHeader >
                                   <TableHead>
                                      <TableRow hover>
                                         <TableCell>Inicio</TableCell>
                                         <TableCell>Instalación</TableCell>
                                         <TableCell>Turno</TableCell>
                                         <TableCell>Motivo</TableCell>
                                         <TableCell>H. Ex.</TableCell>
                                         <TableCell>Comentario</TableCell>
                                         <TableCell>Fin</TableCell>
                                         <TableCell>Autor</TableCell>
                                         <TableCell>Acciones</TableCell>
                                      </TableRow>
                                   </TableHead>
                                   <TableBody>
                                      <Fragment>
                                         {editSeguimiento.map(n => {
                                            return (
                                               <TableRow hover key={n.id}>
                                                    <TableCell>{(n.fecha_in && n.fecha_in != "0000-00-00 00:00:00") ? format(parseISO(n.fecha_in), 'dd-MM-yyyy') : ''}</TableCell>
                                                    <TableCell>
                                                        <Tooltip direction="up" title={n.zona} >
                                                            <span>{n.instalacion}</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>{n.turno}</TableCell>
                                                    <TableCell>{n.motivo}</TableCell>
                                                    <TableCell>{n.hora_extra}</TableCell>
                                                    <TableCell>{n.comentario}</TableCell>
                                                    <TableCell>{(n.fecha_fin && n.fecha_fin != "0000-00-00 00:00:00") ? format(parseISO(n.fecha_fin), 'dd-MM-yyyy') : ''}</TableCell>
                                                    <TableCell>
                                                        {n.admin != "" &&
                                                        <Tooltip title={Format(n.admin_rut)+' - '+n.admin_zona} >
                                                            <span>{n.admin}</span>
                                                        </Tooltip>
                                                        }
                                                        {n.admin == "" &&
                                                                <span>-</span>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title="Eliminar" >
                                                            <IconButton  onClick={(e) => this.props.deleteSeguimiento(e,n.id,n.id_user)} className=""  aria-label="Eliminar" style={{padding: 0}}>
                                                              <span edge="start" aria-label="Eliminar" className="material-icons" style={{fontSize: '20px'}}>delete</span>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell> 
                                               </TableRow>
                                            );
                                         })}
                                      </Fragment>
                                   </TableBody>
                                </Table>
                                </TableContainer>
   
                        }
                     </div>
                     </TabContainer>
             </div>
            <div className="card mb-0 transaction-box">
                    <TabContainer dir={theme.direction}>
                    <div className="p-sm-20 pt-sm-30 p-8 pt-10 border-top">
        <FormGroup>
            <Input
                type="hidden"
                name="id_user"
                id="userId"
                readOnly={true}
                disabled={true}
                value={addNewSeguimientoDetails.id_user}
            />
            <FormFeedback>
              RUT incorrecto, o dígito verificador no corresponde
            </FormFeedback>
        </FormGroup>
        <FormGroup>
            <Label for="rut">Rut</Label>
            <Input
                type="text"
                name="rut"
                id="userRut"
                readOnly={true}
                disabled={true}
                placeholder="Ingrese RUT"
                value={Format(selectedSeguimientoUser.rut)}
                onFocus={(e) => this.onFocusRut(e.target.value,'nuevo')}
                onBlur={(e) => this.onBlurRut(e.target.value,'nuevo')}
                onChange={(e) => this.onUpdateSeguimientoDetail('rut', e.target.value)}
            />
            <FormFeedback>
              RUT incorrecto, o dígito verificador no corresponde
            </FormFeedback>
        </FormGroup>
        <FormGroup>
            <Label className="mb-1" for="instalacion_id">Instalacion</Label>
            <FormControl fullWidth>
                <InstalacionesSelect
                    onChantge={this.onUpdateSeguimientoDetail.bind(this)}
                    instalacion={selectedSeguimientoUser.instalacion_id_usuario}
                    name="instalacion_id"
                    skipRefresh={this.blockRefresh}
                    setStatoLoading={this.props.setStatoLoading}
                    setBlockRefresh={this.setBlockRefresh}
                     />
            </FormControl>
        </FormGroup>
            <FormGroup>
                <Label for="fecha">Fecha Inicio</Label>
                <Input
                    type="date"
                    name="fecha"
                    id="hora_extra"
                    placeholder="Ingrese fecha"
                    value={addNewSeguimientoDetails.fecha_in}
                    onChange={(e) => this.onUpdateSeguimientoDetail('fecha_in', e.target.value)}
                />
        </FormGroup>
       <FormGroup>
          <Label for="turno">Turno</Label>
            <Input
                type="text"
                name="turno"
                id="turno"
                placeholder="Ingrese turno"
                value={addNewSeguimientoDetails.turno}
                onChange={(e) => this.onUpdateSeguimientoDetail('turno', e.target.value)}
            />
       
       </FormGroup>
       <FormGroup>
          <Label for="entre">Motivo</Label>
          <FormControl fullWidth>
                <Select name="motivo" id="motivo" value={addNewSeguimientoDetails.motivo} onChange={(e) => this.onUpdateSeguimientoDetail('motivo', e.target.value)}
                >
                    <MenuItem value=''>SELECCIONE</MenuItem>
                    <MenuItem value='RENUNCIA'>RENUNCIA</MenuItem>
                    <MenuItem value='DESVINCULACIÓN'>DESVINCULACIÓN</MenuItem>
                    <MenuItem value='FALLA'>FALLA</MenuItem>
                    <MenuItem value='LICENCIA MEDICA'>LICENCIA MÉDICA</MenuItem>
                    <MenuItem value='PERMISO'>PERMISO</MenuItem>
                    <MenuItem value='CONTRATACION'>CONTRATACIÓN</MenuItem>
                    <MenuItem value='EXTRA'>EXTRA</MenuItem>
                    <MenuItem value='VACACIONES'>VACACIONES</MenuItem>
                    <MenuItem value='EXTRA PAGO'>EXTRA LABORADO (FERIADO)</MenuItem>
                </Select>
        </FormControl>
       
       </FormGroup>
       <FormGroup>
          <Label for="entre">Hora Extra</Label>
            <NumericFormat
                type="text"
                name="hora_extra"
                id="hora_extra"
                thousandSeparator={'.'} decimalSeparator={','}
                placeholder="Ingrese hora extra"
                className="form-control"
                value={addNewSeguimientoDetails.hora_extra}
                onChange={(e) => this.onUpdateSeguimientoDetail('hora_extra', e.target.value)}
            />
       
       </FormGroup>
      <FormGroup>
          <Label for="comentario">Comentario</Label>
            <Input
                type="textarea"
                name="comentario"
                id="motivo"
                placeholder="Ingrese comentario"
                value={addNewSeguimientoDetails.comentario}
                onChange={(e) => this.onUpdateSeguimientoDetail('comentario', e.target.value)}
            />
       </FormGroup>
       <FormGroup>
                <Label for="fecha_fin">Fecha Fin (opcional)</Label>
                <Input
                    type="date"
                    name="fecha_fin"
                    id="fecha_fin"
                    placeholder="Ingrese fecha"
                    value={addNewSeguimientoDetails.fecha_fin}
                    onChange={(e) => this.onUpdateSeguimientoDetail('fecha_fin', e.target.value)}
                />
        </FormGroup>
        </div>
        </TabContainer>
        </div>
        </SwipeableViews>
        </div>
    </Form>
    </Fragment> 
    }
    </ModalBody>
        <ModalFooter>
            {valueTab === 1 && addNewSeguimientoDetails !== null && selectedSeguimientoUser !== null && 
                <Button variant="contained" color="primary" className="text-white" onClick={(e) => this.props.updateSeguimiento(e, addNewSeguimientoDetails)} >Registrar Seguimiento</Button> 
            }
            <Button variant="contained" className="text-white btn-danger" onClick={() => this.props.onAddUpdateSeguimientoModalClose()} >Cerrar</Button>
        </ModalFooter>
       </Fragment>               
        );
    };
}

export default (withStyles(null, { withTheme: true })(UpdateSeguimientoForm));
