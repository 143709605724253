import React, { Component, Fragment } from 'react';
import { Collapse, Badge } from 'reactstrap';
import classnames from 'classnames';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class InstalacionesSelect extends Component {

	state = {
            instalacion : this.props.instalacion,
            items: null,
            loadingSelect : true
	}
        
        componentDidMount() {
            if(!this.props.skipRefresh){
            this.props.setStatoLoading(true);
            axios.get('/instalaciones', {}).then((response) => {
              let items = [];  
              if(response.data && response.data.length > 0){
                  for(let i=0; i<response.data.length; i++){
                      let datos = {};
                      datos.id = response.data[i].id;
                      datos.instalacion = response.data[i].instalacion;
                      datos.zona = response.data[i].zona;
                      items.push(datos);
                  }  
               }
               this.setState({ items: items, loadingSelect: false });
               this.props.setStatoLoading(false);
            }, (error) => {
                this.setState({ loadingSelect: false });
            });
            } else {
                this.props.setBlockRefresh(false);
            }
        }
        
        onChangeSelect(key, value) {
            const {  name } = this.props;
            this.props.onChantge(name, value);
            this.setState({
                ['instalacion']: value
            });
	}

	render() {
		const { instalacion, items, loadingSelect} = this.state;
		const {  name, onChange, loading } = this.props;
                if(!loadingSelect) {
                    return (
                            <Select onChange={(e) => this.onChangeSelect(name, e.target.value)} name={name} id={name} value={instalacion !== null ? instalacion :''}
                            >     
                                  <MenuItem value=''>Seleccione</MenuItem>
                                  {items.map((itm, index) => (
                                    <MenuItem key={index} value={itm.id}>{itm.instalacion} ({itm.zona})</MenuItem>
                                  ))}
                            </Select>
                    );
                }
	}
}

export default InstalacionesSelect;
