    /**
 * Document Management Page
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet";
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import {validate as Validate, clean, format as Format, getCheckDigit} from 'rut.js';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";

import {
	Pagination,
	PaginationItem,
	PaginationLink,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge
} from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import Avatar from '@material-ui/core/Avatar';
import { format, parseISO  } from 'date-fns';
// api
import api from 'Api';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';

// update document form
import UpdateSeguimientoForm from './UpdateSeguimientoForm';

import { TableCell } from '@material-ui/core'

const oldRender = TableCell.render

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

export default class Seguimiento extends React.Component {
        
        state = {
		all: false,
		Seguimientos: null, // initial document data
		selectedSeguimiento: null, // selected document to perform operations
                selectedSeguimientoUser: null, // selected document to perform operations
		loading: true, // loading activity
                valueTab: 0,
                loadingModal: false, // loading activity
		addNewSeguimientoModal: false,
                Seguimiento: null,// add new document form modal
                seguimiento: null,
                maxDateNew : new Date().setFullYear(new Date().getFullYear() + 1),
                minDateNew: new Date(new Date().getFullYear() - 4, new Date().getMonth(), new Date().getDate()),
		addNewSeguimientoDetail: {
			id: '',
			fecha_in: '',
                        fecha_fin: '',
			instalacion: '',
                        zona: '',
                        instalacion_id: '',
                        instalacion_id_usuario:'',
			name: '',
                        rut: '',
                        turno: '',
                        motivo: '',
                        hora_extra: '',
                        user_id: '',
                        id_user: '',
                        comentario: '',
                        ocupado: 'Disponible',
			checked: false,
                        
		},
                selectedDate: new Date(),
		openViewSeguimientoDialog: false, // view document dialog box
		editSeguimiento: null,
		allSelected: false,
                validate: {
                    nameState : '',
                    emailState: '',
                    rutState : ''
                  }
	}
        
        setStatoLoading(t){
            this.setState({ loading : t, loadingModal:  t });
        }
        
        handleDateChange = (date) => {
            this.setState({ selectedDate: date, loading: true  });
            var month = (date.getMonth() + 1).toString().padStart(2, "0"); 
            var year = date.getUTCFullYear();
            
            axios.get(`/seguimiento/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].fecha_in);
                            localArray.push(res.data[i].instalacion);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].rut);
                            localArray.push(res.data[i].turno);
                            localArray.push(res.data[i].motivo);
                            localArray.push(res.data[i].hora_extra);
                            localArray.push(res.data[i].comentario);
                            localArray.push(res.data[i].fecha_fin);
                            localArray.push(res.data[i].ocupado);
                            localArray.push(res.data[i].id_user);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading: false, dataTable: result, seguimiento: res.data, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
        };
        
        componentWillMount(){

            const { location, history} = this.props;
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rol');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {
                  let rol = response.data.rol;
                    if(rol != 'administrador' && rol != 'supervisor' ){
                          history.push('/session/404');
                    }
                  this.Init();
              }
            }, (error) => {
                console.log(error);
                
            });
        }
        
        Init(){
            const { selectedDate } = this.state;

            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            
            axios.get(`/seguimiento/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].fecha_in);
                            localArray.push(res.data[i].instalacion);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].rut);
                            localArray.push(res.data[i].turno);
                            localArray.push(res.data[i].motivo);
                            localArray.push(res.data[i].hora_extra);
                            localArray.push(res.data[i].comentario);
                            localArray.push(res.data[i].fecha_fin);
                            localArray.push(res.data[i].ocupado);
                            localArray.push(res.data[i].id_user);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }

                        this.setState({ seguimiento: res.data, loading: false, dataTable: result, rowsSelected: [] });
                    })
                .catch(error => {
                   NotificationManager.error(error.message);
                   this.setState({ loading: false });
             })
          }
          
          /**
	 * Open Add New Document Modal
	 */
	opnAddNewSeguimientoModal(e) {
           
            e.preventDefault();
            this.setState({ addNewSeguimientoModal: true,
                validate: {
                    nameState : '',
                    emailState: '',
                    rutState : ''
                  } });
	}

	/**
	 * On Reload
	 */
        onReload(e) {
            
            const { selectedDate } = this.state; 
            this.setState({ loading: true });
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            
            axios.get(`/seguimiento/`+month+'-'+year)
                .then(
                    (res) => {
                        
                        var result = [];
                        for(var i in res.data){
                            var localArray = [];
                            localArray.push(res.data[i].fecha_in);
                            localArray.push(res.data[i].instalacion);
                            localArray.push(res.data[i].name);
                            localArray.push(res.data[i].rut);
                            localArray.push(res.data[i].turno);
                            localArray.push(res.data[i].motivo);
                            localArray.push(res.data[i].hora_extra);
                            localArray.push(res.data[i].comentario);
                            localArray.push(res.data[i].fecha_fin);
                            localArray.push(res.data[i].ocupado);
                            localArray.push(res.data[i].id_user);
                            localArray.push(res.data[i]);
                            result.push(localArray);
                           }
                        this.setState({  loading: false, dataTable: result, seguimiento: res.data, rowsSelected: [] });
                    })
                .catch(error => {
                   this.setState({ loading: false });
                    if (error.response) {
                        NotificationManager.error(error.response.data.message);
                      } else if (error.request) {
                        NotificationManager.error(error.request);
                      } else {
                        NotificationManager.error(error.message);
                      }
             });
            e.preventDefault();
	}
        
        /**
	 * On Change Add New Document Details
	 */
	onChangeAddNewSeguimientoDetails(key, value) {
		this.setState({
			addNewSeguimientoDetail: {
				...this.state.addNewSeguimientoDetail,
				[key]: value
			}
		});
	}
        
        /**
	 * On Add & Update Document Modal Close
	 */
	onAddUpdateSeguimientoModalClose() {
            
            this.setState({addNewSeguimientoDetail: {
                id: '',
                fecha_in: '',
                fecha_fin: '',
                instalacion: '',
                instalacion_id: '',
                instalacion_id_usuario : '',
                zona: '',
                name: '',
                rut: '',
                turno: '',
                motivo: '',
                hora_extra: '',
                user_id: '',
                id_user: '',
                comentario: '',
                ocupado: 'Disponible',
                checked: false,
            }});
            
            this.setState({ addNewSeguimientoModal: false, editSeguimiento: null })
	}
        
        /**
	 * On Update User Details
	 */
	onUpdateSeguimientoDetails(key, value) {
		this.setState({
			editSeguimiento: {
				...this.state.editSeguimiento,
				[key]: value
			}
		});
	}
        
        /**
	 * View Document Detail Hanlder
	 */
	viewSeguimientoDetail(data) {
            
            const { selectedDate } = this.state; 
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();
            this.setState({ loading: true});
            axios.get(`/seguimiento/detalle/`+month+'-'+year+'/'+data.id_user)
                  .then(
                      (res) => {
                         
                         this.setState({ openViewSeguimientoDialog: true, selectedSeguimiento: res.data, selectedSeguimientoUser: data,  loading:false });
                      })
                  .catch((error) => {

                  this.setState({ loading: false });
                  if (error.response) {
                      NotificationManager.error(error.response.data.message);
                    } else if (error.request) {
                      NotificationManager.error(error.request);
                    } else {
                      NotificationManager.error(error.message);
                    }
                   })
	}

	/**
	 * On Edit Document
	 */
	onEditSeguimiento(data, index) {
            
            const { selectedDate } = this.state; 
            var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            var year = selectedDate.getUTCFullYear();

            this.setState({ loading: true});
            axios.get(`/seguimiento/detalle/`+month+'-'+year+'/'+data.id_user)
                  .then(
                      (res) => {
                          this.setState({ 
                                selectedSeguimientoUser: data, editSeguimiento: res.data,
                                addNewSeguimientoDetail: {
                                          ...this.state.addNewSeguimientoDetail,
                                          ['rut']: data.rut,
                                          ['id_user']: data.id_user,
                                          ['name']: data.name,
                                          ['instalacion']: data.instalacion,
                                          ['instalacion_id']: data.instalacion_id_usuario
                                          },
                                 editSeguimientoIndex : index, /*loading:false,*/ validate: {
                                    nameState : ''
                                   },
                               addNewSeguimientoModal: true,})
                      })
                      
                  .catch((error) => {

                  this.setState({ loading: false });
                  if (error.response) {
                      NotificationManager.error(error.response.data.message);
                    } else if (error.request) {
                      NotificationManager.error(error.request);
                    } else {
                      NotificationManager.error(error.message);
                    }
                   })

	}
        
        onFocusRut(rut,accion){
        if (rut != ''){
                rut = clean(rut);
                if(accion == 'nuevo'){
                this.setState({
			addNewSeguimientoDetail: {
				...this.state.addNewSeguimientoDetail,
				['rut']: rut
			}
		});
                } else if (accion == 'editar'){
                  this.setState({
			editSeguimiento: {
				...this.state.editSeguimiento,
				['rut']: rut
			}
                    });                      
                }
            }
        }
        onBlurRut(rut,accion) {
            if (rut != '') {
                if (rut.length > 3) {

                    rut = Format(rut);
                    if(accion == 'nuevo'){
                        this.setState({
                           addNewSeguimientoDetail: {
                                       ...this.state.addNewSeguimientoDetail,
                                       ['rut']: rut
                               }
                       });
                    }else if (accion == 'editar'){
                        this.setState({
			editSeguimiento: {
				...this.state.editSeguimiento,
				['rut']: rut
			}
                    });
                    }
                } 
            } 
        }
   
        
        /**
	 * On Delete
	 */
	onDelete(data) {
		this.refs.deleteConfirmationDialog.open();
		this.setState({ selectedSeguimiento: data });
	}
        
        /**
	 * Update Document
	 */
	updateSeguimiento(e, dataSeguimiento) {
            
		const { editSeguimiento, editSeguimientoIndex, validate, seguimiento, valueTab, selectedSeguimientoUser } = this.state;
                this.setState({ loadingModal: true, valueTab:1,addNewSeguimientoDetail: dataSeguimiento });

                dataSeguimiento.rut = selectedSeguimientoUser.rut;
                dataSeguimiento.id_user =selectedSeguimientoUser.id_user;
                dataSeguimiento.user_id = selectedSeguimientoUser.id_user;
                dataSeguimiento.instalacion = seguimiento[editSeguimientoIndex].instalacion;
                const { selectedDate } = this.state; 
                var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                var year = selectedDate.getUTCFullYear();

                    axios.post(`/seguimiento/`+month+'-'+year, dataSeguimiento)
                        .then(
                            (res) => {
                                
                                var result = [];
                                for(var i in res.data.seguimientos){
                                    var localArray = [];
                        
                                    localArray.push(res.data.seguimientos[i].fecha_in);
                                    localArray.push(res.data.seguimientos[i].instalacion);
                                    localArray.push(res.data.seguimientos[i].name);
                                    localArray.push(res.data.seguimientos[i].rut);
                                    localArray.push(res.data.seguimientos[i].turno);
                                    localArray.push(res.data.seguimientos[i].motivo);
                                    localArray.push(res.data.seguimientos[i].hora_extra);
                                    localArray.push(res.data.seguimientos[i].comentario);
                                    localArray.push(res.data.seguimientos[i].fecha_fin);
                                    localArray.push(res.data.seguimientos[i].ocupado);
                                    localArray.push(res.data.seguimientos[i].id_user);
                                    localArray.push(res.data.seguimientos[i]);
                                    result.push(localArray);
                                }
                                
                                
                                 this.setState({addNewSeguimientoDetail: {
                                    id: '',
                                    fecha_in: '',
                                    fecha_fin: '',
                                    instalacion: '',
                                    instalacion_id: '',
                                    instalacion_id_usuario:'',
                                    zona: '',
                                    name: '',
                                    rut: '',
                                    turno: '',
                                    motivo: '',
                                    hora_extra: '',
                                    user_id: '',
                                    id_user: '',
                                    comentario: '',
                                    ocupado: 'Disponible',
                                    checked: false,
                                }});

                                this.setState({ valueTab:0, loadingModal: false,dataTable: result, rowsSelected: [], seguimiento : res.data.seguimientos, editSeguimiento:  res.data.seguimiento });
                                NotificationManager.success(res.data.message);
   
                            })
                        .catch((error) => {

                            this.setState({ loadingModal: false, valueTab:1, addNewSeguimientoDetail: dataSeguimiento});
                            if (error.response) {
                                if(Array.isArray(error.response.data.message)){
                                    for(let i=0;i<error.response.data.message.length;i++){
                                        NotificationManager.error(error.response.data.message[i]);
                                    }
                                } else {
                                    NotificationManager.error(error.response.data.message);
                                }
                               } else if (error.request) {
                                 NotificationManager.error(error.request);
                               } else {
                                 NotificationManager.error(error.message);
                               }
                           });
	}
        
        /**
	 * Delete Document Permanently
	 */
	/**
	 * Update Document
	 */
	deleteSeguimiento(e, id,user_id) {
               
		const { editSeguimiento, editSeguimientoIndex, validate, seguimiento, valueTab, selectedDate,selectedSeguimientoUser } = this.state;
                this.setState({ loadingModal: true});
                var month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                var year = selectedDate.getUTCFullYear();
            
                    axios.delete(`/seguimiento/`+id+'/'+user_id+'/'+month+'-'+year)
                        .then(
                            (res) => {
                                
                                var result = [];
                                for(var i in res.data.seguimientos){
                                    var localArray = [];
                        
                                    localArray.push(res.data.seguimientos[i].fecha_in);
                                    localArray.push(res.data.seguimientos[i].instalacion);
                                    localArray.push(res.data.seguimientos[i].name);
                                    localArray.push(res.data.seguimientos[i].rut);
                                    localArray.push(res.data.seguimientos[i].turno);
                                    localArray.push(res.data.seguimientos[i].motivo);
                                    localArray.push(res.data.seguimientos[i].hora_extra);
                                    localArray.push(res.data.seguimientos[i].comentario);
                                    localArray.push(res.data.seguimientos[i].fecha_fin);
                                    localArray.push(res.data.seguimientos[i].ocupado);
                                    localArray.push(res.data.seguimientos[i].id_user);
                                    localArray.push(res.data.seguimientos[i]);
                                    result.push(localArray);
                                }
                                
                                
                        
                                this.setState({ valueTab:0, loadingModal: false,dataTable: result, rowsSelected: [], seguimiento : res.data.seguimientos, editSeguimiento:  res.data.seguimiento });
                                NotificationManager.success(res.data.message);
   
                            })
                        .catch((error) => {

                            this.setState({ loadingModal: false });

                            if (error.response) {
                                if(Array.isArray(error.response.data.message)){
                                    for(let i=0;i<error.response.data.message.length;i++){
                                        NotificationManager.error(error.response.data.message[i]);
                                    }
                                } else {
                                    NotificationManager.error(error.response.data.message);
                                }
                               } else if (error.request) {
                                 NotificationManager.error(error.request);
                               } else {
                                 NotificationManager.error(error.message);
                               }
                           });
	}

        validateName(name){
            const { validate } = this.state;
            if(name == "" ){
                validate.nameState = 'has-danger';
            } else {
                validate.nameState = 'has-success';
            }
            this.setState({ validate });
        }
        
        render() {
            
            const { seguimiento, loading, loadingModal,
                editSeguimiento, allSelected, selectedSeguimiento, selectedSeguimientoUser, dataTable,
                editSeguimientoIndex, addNewSeguimientoDetail,  maxDateNew, minDateNew,
                valueTab, selectedDate  } = this.state;

            const columns = [ 

                             {"name": "Inicio","label":"Inicio",
                                options: {
                                    filter:false,
                                    customBodyRender: (value) => {
                                        if(value != "" && value != "0000-00-00 00:00:00"){
                                            return format(parseISO(value), 'dd-MM-yyyy');
                                        } else {
                                            return value;
                                        }
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                    
                              },},
                           {"name": "Instalación","label":"Instalación",
                                options: {
                                    filter: true,
                                    options: { sortDirection: 'asc' },
                                   customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                   setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                    
                              },},
                          {"name":"Nombre",
                             "label":"Nombre",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"RUT",
                             "label":"RUT",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    customBodyRender: (value) => {
                                        return Format(value)
                                    },
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                    }},
                            
                            {"name":"Turno",
                             "label":"Turno",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                              {"name":"Motivo",
                             "label":"Motivo",
                                options: {
                                    filter: true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            
                            {"name":"Hora Extra",
                             "label":"Hora Extra",
                                options: {
                                    filter: false,
                                    viewColumns:true,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    customBodyRender: (value) => {
                                        return /*format(parseISO(*/value/*), 'dd-MM-yyyy H:mm')*/
                                    },
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                             {"name":"Comentario",
                             "label":"Comentario",
                                options: {
                                    filter: false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name": "Fin","label":"Fin",
                                options: {
                                    filter:false,
                                    customBodyRender: (value) => {
                                        if(value != "" && value != "0000-00-00 00:00:00"){
                                            return format(parseISO(value), 'dd-MM-yyyy');
                                        } else {
                                            return value;
                                        }   
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                              },},
                          {"name":"Disponibilidad",
                             "label":"Disponibilidad",
                                options: {
                                    filter: true,
                                    display : false,
                                    viewColumns:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"user_id",
                             "label":"user_id",
                                options: {
                                    filter: false,
                                    display : false,
                                    viewColumns:false,
                                    customHeadLabelRender: (columnMeta) => ( <b>{columnMeta.name}</b> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                                }},
                            {"name":"Acciones","label":"Acciones",
                                
                                options: {
                                    filter: false,
                                    sort: false,
                                    download: false,
                                    customBodyRender: (value, tableMeta) => { 
                                                       
                                            return (
                                                    <div className="list-action text-center">
                                                        <Tooltip title="Ver / Editar">
                                                            <button type="button" className="rct-link-btn" onClick={() => this.onEditSeguimiento(value,tableMeta.rowIndex)}><i className="ti-pencil"></i></button>
                                                        </Tooltip>
                                                    </div>
                                                );
                                    },
                                    customHeadLabelRender: (columnMeta) => ( <div className="text-center"><b>{columnMeta.name}</b></div> ),
                                    setCellProps: (cellValue, rowIndex, columnIndex) => {
                                        if(this.state.seguimiento != null && this.state.seguimiento[rowIndex].ocupado == 'No disponible'){
                                            return {
                                                className:  'text-white',
                                           };
                                        }
                                      }
                              }},
                            ];
                    const data = dataTable;
                    const options = {
			filterType: 'dropdown',
                        tableBodyMaxHeight:'500px',
                        tableBodyHeight:'500px',
                        setRowProps: (row, dataIndex, rowIndex) => {
                            if(row[9] == 'No disponible'){
                                return {
                                   style: { background: /*'rgb(255, 145, 110)'*/ '#CC5A65'},
                                };
                            }
                          },
                        downloadOptions : {filename: 'seguimiento_vista.csv', separator: ';', filterOptions:{ useDisplayedRowsOnly : true }},
                        download: true,
                        print: false,
                        selectableRows : 'none',
                        textLabels: {
                            body: {
                              noMatch: "Lo sentimos, no se encotraron registros",
                              toolTip: "Ordenar",
                              columnHeaderTooltip: column => `Ordenar por ${column.label}`
                            },
                            pagination: {
                              next: "Siguiente",
                              previous: "Anterior",
                              rowsPerPage: "Fillas por página:",
                              displayRows: "de",
                            },
                            toolbar: {
                              search: "Buscar",
                              downloadCsv: "Descarga vista CSV",
                              //print: "Print",
                              viewColumns: "Ver Columnas",
                              filterTable: "Filtrar",
                            },
                            filter: {
                              all: "Todas",
                              title: "FILTRAR",
                              reset: "REINICIAR",
                            },
                            viewColumns: {
                              title: "Mostrar Columnas",
                              titleAria: "Mostrar/Ocultar Columnas",
                            }
                          },
                          /*sortOrder: {
                                name: 'Nombre',
                                direction: 'asc',
                            },*/
                            onRowsDelete: (rowsDeleted) => {
                                let documents = this.state.documents;
                                let idsToDelete = rowsDeleted.data.map (item => item.dataIndex);
                                let obj = [];
                                for(let i=0;i<idsToDelete.length;i++){
                                    obj.push(documents[idsToDelete[i]].id)
                                }
      
                                this.refs.deleteManyConfirmationDialog.open();
                                this.setState({ allSelected: obj });
                    
                                return false;
                            },
                            rowsSelected: this.state.rowsSelected,
                            onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
                              this.setState({ rowsSelected: rowsSelected });
                            },
                            customToolbar: (selectedRows, displayData, setSelectedRows) => {
 
                                 const handleClickDownload = (e) => {
                                     e.preventDefault();
                                    let ahora = Date.now();
                                    var month = (this.state.selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                                    var year = this.state.selectedDate.getUTCFullYear();
                                    this.setState({  loading: true});
                                    axios.get(`/seguimiento/download/`+month+'-'+year)
                                        .then(
                                            (res) => {
                                                let csvContent = "data:text/csv;charset=utf-8,";
                                                
                                                if(res.data.length > 0){
                                        
                                                    csvContent += "Inicio;Instalacion;Zona;Nombre;Rut;Turno;Motivo;Hora_Extra;Comentario;Fin;Responsable\r\n";

                                                    res.data.forEach(function(value, index) {
                                                        csvContent += value.fecha_in+";";
                                                        csvContent += value.instalacion+";";
                                                        csvContent += value.zona+";";
                                                        csvContent += value.name+";";
                                                        csvContent += value.rut+";";
                                                        csvContent += value.turno+";";
                                                        csvContent += value.motivo+";";
                                                        csvContent += value.hora_extra+";";
                                                        csvContent += value.comentario+";";
                                                        csvContent += value.fecha_fin+";";
                                                        if(value.admin != ""){
                                                            csvContent += value.admin+" ("+value.admin_rut+"-"+value.admin_zona+");\r\n";
                                                        }else {
                                                            csvContent += "-;\r\n";
                                                        }
                                                    });
                                                    
                                                    let encodedUri = encodeURI(csvContent);
                                                    let link = document.createElement("a");
                                                    link.setAttribute("href", encodedUri);
                                                    link.setAttribute("download", "seguimiento_completo_"+month+'-'+year+"_"+ahora+".csv");
                                                    document.body.appendChild(link);
                                                    link.click();
                                                } else {
                                                    NotificationManager.error("No hay registros con la información solicitada");
                                                }
    
                                                this.setState({  loading: false});
                                                return true;
                                            })
                                        .catch(error => {
                                           this.setState({ loading: false });
                                            if (error.response) {
                                                NotificationManager.error(error.response.data.message);
                                              } else if (error.request) {
                                                NotificationManager.error(error.request);
                                              } else {
                                                NotificationManager.error(error.message);
                                              }
                                     });
                                    e.preventDefault();
                                 };

                                return (
                                        <Fragment>
                                                <Tooltip title="Descarga completa CSV">
                                                    <IconButton onClick={(e) => handleClickDownload(e)}  aria-label="Descarga completa CSV">
                                                      <span edge="start" aria-label="Descargar CSV" className="material-icons">download</span>
                                                    </IconButton>
                                                </Tooltip>
                                        </Fragment>
                                        )
                         },
   
                    };
     
     
            return ( <div className="seguimiento-management">            
                <Helmet>
                        <title>{process.env.MIX_APP_NAME} | Gestión de Seguimiento</title>
                        <meta name="description" content="Gestión de Seguimiento" />
                </Helmet>
                <PageTitleBar
                        title={<IntlMessages id="Formulario de Seguimiento" />}
                        match={this.props.match}
                />
                <RctCollapsibleCard  fullBlock>
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="#" onClick={(e) => this.onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"></i></a>
                            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                                <DatePicker
                                        views={['year', 'month']}
                                        label="Selecciona periodo"
                                        autoOk
                                        showTabs={true}
                                        maxDate={maxDateNew}
                                        minDate={minDateNew}
                                        dateformat="MM-YYYY"
                                        value={selectedDate}
                                        onChange={this.handleDateChange}
                                        showmonthyearpicker="true"
                                        leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <MUIDataTable className="table table-middle table-hover mb-0"
                            title={"Listado de Seguimientos"}
                            data={data}
                            columns={columns}
                            options={options}
                    />
                    { loading &&
                            <RctSectionLoader />
                    }    
                </RctCollapsibleCard>
                    <Modal size='lg' isOpen={this.state.addNewSeguimientoModal} toggle={() => this.onAddUpdateSeguimientoModalClose()} backdrop="static">
                            { loadingModal &&
                                    <RctSectionLoader />
                            } 
                            <ModalHeader toggle={() => this.onAddUpdateSeguimientoModalClose()}>
                                    {editSeguimiento !== null &&
                                      <Fragment>Seguimiento <strong>{selectedSeguimientoUser.name}</strong> <small>({Format(selectedSeguimientoUser.rut)})</small></Fragment>
                                    }
                            </ModalHeader>
                            <ModalBody>
                                    {editSeguimiento != null ?
                                            <UpdateSeguimientoForm
                                                    addNewSeguimientoDetails={addNewSeguimientoDetail}
                                                    editSeguimiento={this.state.editSeguimiento}
                                                    onAddUpdateSeguimientoModalClose={this.onAddUpdateSeguimientoModalClose.bind(this)}
                                                    selectedSeguimientoUser={selectedSeguimientoUser}
                                                    onUpdateSeguimientoDetail={this.onUpdateSeguimientoDetails.bind(this)} 
                                                    updateSeguimiento={this.updateSeguimiento.bind(this)}
                                                    deleteSeguimiento={this.deleteSeguimiento.bind(this)}
                                                    index={editSeguimientoIndex} 
                                                    valueTab={this.state.valueTab}
                                                    setStatoLoading={this.setStatoLoading.bind(this)}
                                            />
                                            : <UpdateSeguimientoForm />
                                    }
                            </ModalBody>
                    </Modal>
            </div>);
                                
        }
}