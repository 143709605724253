/**
 * Dashboard
 */

import React, { Component, Fragment } from 'react'
import { Alert } from 'reactstrap';
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import MatButton from '@material-ui/core/Button';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import Button from '@material-ui/core/Button';
import { format, parseISO  } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { NotificationManager } from 'react-notifications';
// app config
import { connect } from 'react-redux';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// rct collapsible card
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import { RctCard, RctCardContent } from 'Components/RctCard';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";

import {
	VisitorAreaChartWidget,
	SalesAreaChartWidget,
	OrdersAreaChartWidget,
	RecentOrdersWidget,
	SupportRequest,
	Notifications,
	TopSellingWidget,
	OverallTrafficStatusWidget,
	ProductReportsWidget,
	OnlineVisitorsWidget,
	TodayOrdersStatsWidget,
	BookingInfo,
	NewOrderCountdown,
	FollowersWidget,
	Notes
} from "Components/Widgets";

// widgets data
import {
	visitorsData,
	salesData,
	ordersData,
	topSellingProducts,
	trafficStatus,
} from './data';

import axios from "axios";

import { Bar, Line } from 'react-chartjs-2';
import ChartConfig from 'Constants/chart-config';
import { Badge } from 'reactstrap';
import MatBadge from '@material-ui/core/Badge';

class Dashboard extends Component {
        
        constructor(props) {
      
        super(props);
            this.state = {
              ready: true,
              visible: true,
              loading: true,
              loadingGrafico1 : true,
              loadingGrafico2 : true,
              Grafico1Cantidad : null,
              Grafico1Label : null,
              Grafico1AysenCantidad : null,
              Grafico1AysenLabel : null,
              Grafico1PmonttCantidad : null,
              Grafico1PmonttLabel : null,
              Grafico1ChiloeCantidad : null,
              Grafico1ChiloeLabel : null,
              Grafico1Cantidad2 : null,
              Grafico1Labe2l : null,
              Grafico1AysenCantidad2 : null,
              Grafico1AysenLabe2l : null,
              Grafico1PmonttCantidad2 : null,
              Grafico1PmonttLabel2 : null,
              Grafico1ChiloeCantidad2 : null,
              Grafico1ChiloeLabel2 : null,
              selectedDate: new Date(),
              activeChiloe : '',
              activeAysen : '',
              activePmontt : '',
              activeChiloe2 : 'line-through',
              activeAysen2 : '',
              activePmontt2 : 'line-through',
              totalChiloe : 0,
              totalAysen : 0,
              totalPmontt : 0,
              totalTodos : 0,
              last_activity: '',
              dataLineDiasAysen : 0,
              dataLineActivosAysen : 0,
              dataLineNuevosAysen : 0,
              totalAysen2: 0,
              totalChiloe2: 0,
              totalPmontt2: 0,
              maxDate : new Date(),
              minDate: new Date(new Date().getFullYear() - 4, new Date().getMonth(), new Date().getDate())
            };
            this.onDismiss = this.onDismiss.bind(this);
        };
        
        onDismiss() {
            this.setState({ visible: false });
         }
        
         onrefreshGraph1 = (e) => {
             e.preventDefault();
             this.setState({ loadingGrafico1: true });
             axios.get('/users/graphic-instalation', {}).then((response) => {
              if(response.data){
                  let grafico1Cantidad = [];
                  let grafico1Label = [];
                  let grafico1AysenCantidad = [];
                  let grafico1AysenLabel = [];
                  let grafico1PmonttCantidad = [];
                  let grafico1PmonttLabel = [];
                  let grafico1ChiloeCantidad = [];
                  let grafico1ChiloeLabel = [];
                  let totalChiloe = 0;
                  let totalAysen = 0;
                  let totalPmontt = 0;
                  let totalTodos = 0;
                  for(let i=0;i<response.data.length;i++){
                      
                      if(response.data[i].zona === ''){
                          continue;
                      }
                      
                      grafico1Cantidad.push(response.data[i].cantidad);
                      if(response.data[i].diferenciador > 1){
                          grafico1Label.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                      } else {
                          grafico1Label.push(response.data[i].instalacion);
                      }
                      totalTodos = totalTodos+response.data[i].cantidad;
                      if(response.data[i].zona === 'PUERTO AYSEN'){
                          grafico1AysenCantidad.push(response.data[i].cantidad);
                          if(response.data[i].diferenciador > 1){
                              grafico1AysenLabel.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                          } else {
                              grafico1AysenLabel.push(response.data[i].instalacion);
                          }
                          totalAysen = totalAysen+response.data[i].cantidad;
                      }else if(response.data[i].zona === 'PUERTO MONTT'){
                            grafico1PmonttCantidad.push(response.data[i].cantidad);
                            if(response.data[i].diferenciador > 1){
                                grafico1PmonttLabel.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                            }else {
                                grafico1PmonttLabel.push(response.data[i].instalacion);
                            }
                            totalPmontt = totalPmontt+response.data[i].cantidad;
                      }else if(response.data[i].zona === 'CHILOE'){
                            grafico1ChiloeCantidad.push(response.data[i].cantidad);
                            if(response.data[i].diferenciador > 1){
                               grafico1ChiloeLabel.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                            }else {
                                grafico1ChiloeLabel.push(response.data[i].instalacion);
                            }
                            totalChiloe = totalChiloe+response.data[i].cantidad;
                        }
  
                  }
                  this.setState({ loadingGrafico1 : false,
                      Grafico1Cantidad:grafico1Cantidad, Grafico1Label:grafico1Label,
                      Grafico1AysenCantidad:grafico1AysenCantidad, Grafico1AysenLabel:grafico1AysenLabel,
                      Grafico1PmonttCantidad:grafico1PmonttCantidad, Grafico1PmonttLabel:grafico1PmonttLabel,
                      Grafico1ChiloeCantidad:grafico1ChiloeCantidad, Grafico1ChiloeLabel:grafico1ChiloeLabel,
                      activeAysen : '', activeChiloe: '', activePmontt:'',
                      totalChiloe:totalChiloe, totalPmontt:totalPmontt,totalAysen:totalAysen, totalTodos: totalTodos
                  });
              } else {
                  this.setState({ loadingGrafico1 : false});  
              }
            }, (error) => {
                console.log(error);
                this.setState({ loadingGrafico1 : false});
            }); 
        }
        
        onrefreshGraph2 = (e) => {
             e.preventDefault();
             this.setState({ loadingGrafico2: true });
             
             const { selectedDate } = this.state;

            let month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
            let year = selectedDate.getUTCFullYear();

             axios.get('/users/graphic-licencias/'+year+'-'+month, {}).then((response) => {
              if(response.data){
                    let dataLineDiasAysen = [];
                        let dataLineActivosAysen = [];
                        let dataLineNuevosAysen = [];
                        
                        let dataLineDiasChiloe = [];
                        let dataLineActivosChiloe = [];
                        let dataLineNuevosChiloe = [];
                        
                        let dataLineDiasPmontt = [];
                        let dataLineActivosPmontt = [];
                        let dataLineNuevosPmontt = [];

                        for(let i=0;i<response.data.dia.length;i++){
                            
                            dataLineDiasAysen.push(response.data.dia[i]);
                            dataLineActivosAysen.push(response.data.activasAysen[i]);
                            dataLineNuevosAysen.push(response.data.nuevasAysen[i]);
                            
                            dataLineDiasChiloe.push(response.data.dia[i]);
                            dataLineActivosChiloe.push(response.data.activasChiloe[i]);
                            dataLineNuevosChiloe.push(response.data.nuevasChiloe[i]);
          
                            dataLineDiasPmontt.push(response.data.dia[i]);
                            dataLineActivosPmontt.push(response.data.activasPmontt[i]);
                            dataLineNuevosPmontt.push(response.data.nuevasPmontt[i]);

                        }
                        if(this.state.activeChiloe2 === ''){
                               this.setState({ loadingGrafico2 : false,
                                    dataLineNuevos:dataLineNuevosChiloe, dataLineDias:dataLineDiasChiloe,
                                    dataLineActivos:dataLineActivosChiloe,
                                    dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                                    dataLineActivosAysen:dataLineActivosAysen,
                                    dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                                    dataLineActivosChiloe:dataLineActivosChiloe,
                                    dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                                    dataLineActivosPmontt:dataLineActivosPmontt,
                                    totalAysen2: response.data.totalAysen,
                                    totalChiloe2: response.data.totalChiloe,
                                    totalPmontt2: response.data.totalPmontt
                                });
                           } else if(this.state.activeAysen2 === ''){
                               this.setState({ loadingGrafico2 : false,
                                    dataLineNuevos:dataLineNuevosAysen, dataLineDias:dataLineDiasAysen,
                                    dataLineActivos:dataLineActivosAysen,
                                    dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                                    dataLineActivosAysen:dataLineActivosAysen,
                                    dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                                    dataLineActivosChiloe:dataLineActivosChiloe,
                                    dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                                    dataLineActivosPmontt:dataLineActivosPmontt,
                                    totalAysen2: response.data.totalAysen,
                                    totalChiloe2: response.data.totalChiloe,
                                    totalPmontt2: response.data.totalPmontt
                                });
                           } else if(this.state.activePmontt2 === ''){
                               this.setState({ loadingGrafico2 : false,
                                    dataLineNuevos:dataLineNuevosPmontt, dataLineDias:dataLineDiasPmontt,
                                    dataLineActivos:dataLineActivosPmontt,
                                    dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                                    dataLineActivosAysen:dataLineActivosAysen,
                                    dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                                    dataLineActivosChiloe:dataLineActivosChiloe,
                                    dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                                    dataLineActivosPmontt:dataLineActivosPmontt,
                                    totalAysen2: response.data.totalAysen,
                                    totalChiloe2: response.data.totalChiloe,
                                    totalPmontt2: response.data.totalPmontt
                                });
                           } 
              } else {
                  this.setState({ loadingGrafico2 : false});  
              }
            }, (error) => {
                console.log(error);
                this.setState({ loadingGrafico2 : false});
            }); 
        }
             
         componentWillMount() {

            const { location, history, darkMode} = this.props;
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rol');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {

                  this.Init();
              }
            }, (error) => {
                console.log(error);

            });

        }
        
        Init(){
            
            this.setState({ loading:true });

            axios.get(`/users/last-activity`)
               .then(res => new Promise(resolve => setTimeout(() => resolve( 
             this.setState({ loading:false, last_activity : res.data[0].last_active_at })), 0)))
     
             if(localStorage.getItem("rol") == 'administrador'){
     
                axios.get('/users/graphic-instalation', {}).then((response) => {
                   if(response.data){
                        let grafico1Cantidad = [];
                        let grafico1Label = [];
                        let grafico1AysenCantidad = [];
                        let grafico1AysenLabel = [];
                        let grafico1PmonttCantidad = [];
                        let grafico1PmonttLabel = [];
                        let grafico1ChiloeCantidad = [];
                        let grafico1ChiloeLabel = [];
                        let totalChiloe = 0;
                        let totalAysen = 0;
                        let totalPmontt = 0;
                        let totalTodos = 0;
                        for(let i=0;i<response.data.length;i++){
                            
                            if(response.data[i].zona === ''){
                                continue;
                            }
                            
                            grafico1Cantidad.push(response.data[i].cantidad);
                            if(response.data[i].diferenciador > 1){
                                grafico1Label.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                            } else {
                                grafico1Label.push(response.data[i].instalacion);
                            }
                            totalTodos = totalTodos+response.data[i].cantidad;
                            if(response.data[i].zona === 'PUERTO AYSEN'){
                                grafico1AysenCantidad.push(response.data[i].cantidad);
                                if(response.data[i].diferenciador > 1){
                                    grafico1AysenLabel.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                                } else {
                                    grafico1AysenLabel.push(response.data[i].instalacion);
                                }
                                totalAysen = totalAysen+response.data[i].cantidad;
                            }else if(response.data[i].zona === 'PUERTO MONTT'){
                                  grafico1PmonttCantidad.push(response.data[i].cantidad);
                                  if(response.data[i].diferenciador > 1){
                                      grafico1PmonttLabel.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                                  }else {
                                      grafico1PmonttLabel.push(response.data[i].instalacion);
                                  }
                                  totalPmontt = totalPmontt+response.data[i].cantidad;
                            }else if(response.data[i].zona === 'CHILOE'){
                                  grafico1ChiloeCantidad.push(response.data[i].cantidad);
                                  if(response.data[i].diferenciador > 1){
                                     grafico1ChiloeLabel.push(response.data[i].instalacion+' ('+response.data[i].diferenciador+')');
                                  }else {
                                      grafico1ChiloeLabel.push(response.data[i].instalacion);
                                  }
                                  totalChiloe = totalChiloe+response.data[i].cantidad;
                              }

                        }
                        this.setState({ loadingGrafico1 : false,
                            Grafico1Cantidad:grafico1Cantidad, Grafico1Label:grafico1Label,
                            Grafico1AysenCantidad:grafico1AysenCantidad, Grafico1AysenLabel:grafico1AysenLabel,
                            Grafico1PmonttCantidad:grafico1PmonttCantidad, Grafico1PmonttLabel:grafico1PmonttLabel,
                            Grafico1ChiloeCantidad:grafico1ChiloeCantidad, Grafico1ChiloeLabel:grafico1ChiloeLabel,
                            totalChiloe:totalChiloe, totalPmontt:totalPmontt,totalAysen:totalAysen, totalTodos: totalTodos
                        });
                   } else {
                       this.setState({ loadingGrafico1 : false});  
                   }
                 }, (error) => {
                     console.log(error);
                     this.setState({ loadingGrafico1 : false});
                 });
                 
                const { selectedDate } = this.state;

                let month = (selectedDate.getMonth() + 1).toString().padStart(2, "0"); 
                let year = selectedDate.getUTCFullYear();
                  
                 axios.get('/users/graphic-licencias/'+year+'-'+month, {}).then((response) => {
                   if(response.data){

                        let dataLineDiasAysen = [];
                        let dataLineActivosAysen = [];
                        let dataLineNuevosAysen = [];
                        
                        let dataLineDiasChiloe = [];
                        let dataLineActivosChiloe = [];
                        let dataLineNuevosChiloe = [];
                        
                        let dataLineDiasPmontt = [];
                        let dataLineActivosPmontt = [];
                        let dataLineNuevosPmontt = [];

                        for(let i=0;i<response.data.dia.length;i++){
                            
                            dataLineDiasAysen.push(response.data.dia[i]);
                            dataLineActivosAysen.push(response.data.activasAysen[i]);
                            dataLineNuevosAysen.push(response.data.nuevasAysen[i]);
                            
                            dataLineDiasChiloe.push(response.data.dia[i]);
                            dataLineActivosChiloe.push(response.data.activasChiloe[i]);
                            dataLineNuevosChiloe.push(response.data.nuevasChiloe[i]);
          
                            dataLineDiasPmontt.push(response.data.dia[i]);
                            dataLineActivosPmontt.push(response.data.activasPmontt[i]);
                            dataLineNuevosPmontt.push(response.data.nuevasPmontt[i]);

                        }
                        this.setState({ loadingGrafico2 : false,
                            dataLineNuevos:dataLineNuevosAysen, dataLineDias:dataLineDiasAysen,
                            dataLineActivos:dataLineActivosAysen,
                            dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                            dataLineActivosAysen:dataLineActivosAysen,
                            dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                            dataLineActivosChiloe:dataLineActivosChiloe,
                            dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                            dataLineActivosPmontt:dataLineActivosPmontt,
                            totalAysen2: response.data.totalAysen,
                            totalChiloe2: response.data.totalChiloe,
                            totalPmontt2: response.data.totalPmontt
                        });
                   } else {
                       this.setState({ loadingGrafico2 : false});  
                   }
                 }, (error) => {
                     console.log(error);
                     this.setState({ loadingGrafico2 : false});
                 });
             }
            
        }

         componentDidMount(){

         }
         
         cambiaData(e, string){
             e.preventDefault();
             
             let graficoLabel = [];
             let graficoCantidad = [];
             let activeAysen = this.state.activeAysen;
             let activeChiloe = this.state.activeChiloe;
             let activePmontt = this.state.activePmontt;
             
              if (string == 'CHILOE') {
                
                if(this.state.activeChiloe === ''){
                    activeChiloe = 'line-through';
                } else {
                    activeChiloe = '';
                }
    
            }else if (string == 'PMONTT') {
                
                if(this.state.activePmontt === ''){
                    activePmontt = 'line-through';
                } else {
                    activePmontt = '';
                    
                }
              
            }else if(string == 'AYSEN'){
                 
                if(this.state.activeAysen === ''){
                    activeAysen = 'line-through';
                } else {
                    activeAysen = '';
                }
            } 
            
            
            if(activeChiloe == ''){
                graficoLabel = graficoLabel.concat(this.state.Grafico1ChiloeLabel);
                graficoCantidad = graficoCantidad.concat(this.state.Grafico1ChiloeCantidad);
            }
            
            if(activePmontt == ''){
                graficoLabel = graficoLabel.concat(this.state.Grafico1PmonttLabel);
                graficoCantidad = graficoCantidad.concat(this.state.Grafico1PmonttCantidad);
            }
            
            if(activeAysen == ''){
                graficoLabel = graficoLabel.concat(this.state.Grafico1AysenLabel);
                graficoCantidad = graficoCantidad.concat(this.state.Grafico1AysenCantidad);
            }
            
 
            this.setState({ Grafico1Label : graficoLabel, Grafico1Cantidad:graficoCantidad,
                activeAysen:activeAysen, activeChiloe:activeChiloe, activePmontt:activePmontt
            });
         }
         
         cambiaData2(e, string){
             e.preventDefault();
             
             let dataLineNuevos = [];
             let dataLineDias = [];
             let dataLineActivos = [];

             let activeAysen2 = this.state.activeAysen2;
             let activeChiloe2 = this.state.activeChiloe2;
             let activePmontt2 = this.state.activePmontt2;
             
              if (string == 'CHILOE') {
                
                if(this.state.activeChiloe2 === ''){
                    activeChiloe2 = 'line-through';
                } else {
                    activeChiloe2 = '';
                }
                
                activePmontt2 = 'line-through';
                activeAysen2 = 'line-through';
    
            }else if (string == 'PMONTT') {
                
                if(this.state.activePmontt2 === ''){
                    activePmontt2 = 'line-through';
                } else {
                    activePmontt2 = '';
                    
                }
                
                activeChiloe2 = 'line-through';
                activeAysen2 = 'line-through';
              
            }else if(string == 'AYSEN'){
                 
                if(this.state.activeAysen2 === ''){
                    activeAysen2 = 'line-through';
                } else {
                    activeAysen2 = '';
                }
                activeChiloe2 = 'line-through';
                activePmontt2 = 'line-through';
            } 
            
            
            if(activeAysen2 == ''){
                dataLineNuevos = dataLineNuevos.concat(this.state.dataLineNuevosAysen);
                dataLineDias = dataLineDias.concat(this.state.dataLineDiasAysen);
                dataLineActivos = dataLineActivos.concat(this.state.dataLineActivosAysen);

            }
            
            if(activePmontt2 == ''){
                dataLineNuevos = dataLineNuevos.concat(this.state.dataLineNuevosPmontt);
                dataLineDias = dataLineDias.concat(this.state.dataLineDiasPmontt);
                dataLineActivos = dataLineActivos.concat(this.state.dataLineActivosPmontt);
            }
            
            if(activeChiloe2 == ''){
                dataLineNuevos = dataLineNuevos.concat(this.state.dataLineNuevosChiloe);
                dataLineDias = dataLineDias.concat(this.state.dataLineDiasChiloe);
                dataLineActivos = dataLineActivos.concat(this.state.dataLineActivosChiloe);
            }
  
            this.setState({ 
                dataLineNuevos:dataLineNuevos, dataLineDias:dataLineDias,
                dataLineActivos:dataLineActivos,  activeAysen2:activeAysen2, activeChiloe2:activeChiloe2, activePmontt2:activePmontt2
            });

         }
         
         handleDateChange = (date) => {
            this.setState({ selectedDate: date, loadingGrafico2: true });
            var month = (date.getMonth() + 1).toString().padStart(2, "0"); 
            var year = date.getUTCFullYear();
   
                axios.get('/users/graphic-licencias/'+year+'-'+month, {}).then((response) => {
                   if(response.data){

                        let dataLineDiasAysen = [];
                        let dataLineActivosAysen = [];
                        let dataLineNuevosAysen = [];
                        
                        let dataLineDiasChiloe = [];
                        let dataLineActivosChiloe = [];
                        let dataLineNuevosChiloe = [];
                        
                        let dataLineDiasPmontt = [];
                        let dataLineActivosPmontt = [];
                        let dataLineNuevosPmontt = [];

                        for(let i=0;i<response.data.dia.length;i++){
                            
                            dataLineDiasAysen.push(response.data.dia[i]);
                            dataLineActivosAysen.push(response.data.activasAysen[i]);
                            dataLineNuevosAysen.push(response.data.nuevasAysen[i]);
                            
                            dataLineDiasChiloe.push(response.data.dia[i]);
                            dataLineActivosChiloe.push(response.data.activasChiloe[i]);
                            dataLineNuevosChiloe.push(response.data.nuevasChiloe[i]);
          
                            dataLineDiasPmontt.push(response.data.dia[i]);
                            dataLineActivosPmontt.push(response.data.activasPmontt[i]);
                            dataLineNuevosPmontt.push(response.data.nuevasPmontt[i]);

                        }
                        
                           if(this.state.activeChiloe2 === ''){
                               this.setState({ loadingGrafico2 : false,
                                    dataLineNuevos:dataLineNuevosChiloe, dataLineDias:dataLineDiasChiloe,
                                    dataLineActivos:dataLineActivosChiloe,
                                    dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                                    dataLineActivosAysen:dataLineActivosAysen,
                                    dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                                    dataLineActivosChiloe:dataLineActivosChiloe,
                                    dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                                    dataLineActivosPmontt:dataLineActivosPmontt,
                                    totalAysen2: response.data.totalAysen,
                                    totalChiloe2: response.data.totalChiloe,
                                    totalPmontt2: response.data.totalPmontt
                                });
                           } else if(this.state.activeAysen2 === ''){
                               this.setState({ loadingGrafico2 : false,
                                    dataLineNuevos:dataLineNuevosAysen, dataLineDias:dataLineDiasAysen,
                                    dataLineActivos:dataLineActivosAysen,
                                    dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                                    dataLineActivosAysen:dataLineActivosAysen,
                                    dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                                    dataLineActivosChiloe:dataLineActivosChiloe,
                                    dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                                    dataLineActivosPmontt:dataLineActivosPmontt,
                                    totalAysen2: response.data.totalAysen,
                                    totalChiloe2: response.data.totalChiloe,
                                    totalPmontt2: response.data.totalPmontt
                                });
                           } else if(this.state.activePmontt2 === ''){
                               this.setState({ loadingGrafico2 : false,
                                    dataLineNuevos:dataLineNuevosPmontt, dataLineDias:dataLineDiasPmontt,
                                    dataLineActivos:dataLineActivosPmontt,
                                    dataLineNuevosAysen:dataLineNuevosAysen, dataLineDiasAysen:dataLineDiasAysen,
                                    dataLineActivosAysen:dataLineActivosAysen,
                                    dataLineNuevosChiloe:dataLineNuevosChiloe, dataLineDiasChiloe:dataLineDiasChiloe,
                                    dataLineActivosChiloe:dataLineActivosChiloe,
                                    dataLineNuevosPmontt:dataLineNuevosPmontt, dataLineDiasPmontt:dataLineDiasPmontt,
                                    dataLineActivosPmontt:dataLineActivosPmontt,
                                    totalAysen2: response.data.totalAysen,
                                    totalChiloe2: response.data.totalChiloe,
                                    totalPmontt2: response.data.totalPmontt
                                });
                           }  
                   } else {
                       this.setState({ loadingGrafico2 : false});  
                   }
                 }, (error) => {
                     console.log(error);
                     this.setState({ loadingGrafico2 : false});
                 });
        };
        

	render() {
                const {visible, selectedDate, loading, last_activity, loadingGrafico1, loadingGrafico2, Grafico1Cantidad, Grafico1Label, Grafico1Cantidad2, Grafico1Label2,
                activeChiloe, activeAysen, activePmontt,activeChiloe2, activeAysen2, activePmontt2,
                totalAysen , totalPmontt , totalChiloe, totalTodos , totalAysen2 , totalPmontt2 , totalChiloe2, totalTodos2,
                dataLineDias ,dataLineActivos, dataLineNuevos, dataLineNuevosAysen, maxDate, minDate} = this.state;
		const { match, darkMode } = this.props;
                
                const data = {
                    labels: Grafico1Label,
                    datasets: [
                        {
                            backgroundColor: ChartConfig.color.info,
                            borderColor: ChartConfig.color.info,
                            borderWidth: 1,
                            hoverBackgroundColor: ChartConfig.color.info,
                            hoverBorderColor: ChartConfig.color.info,
                            data: Grafico1Cantidad
                        }
                    ]
                };
                
            const options = {
                    maintainAspectRatio: true, 
                    responsive: true,
                    indexAxis: 'x',
                    plugins:{
                            legend: {
                                display:false
                            }	
                    },
                    scales: {
                            x: {
                                    grid: {
                                            color: ChartConfig.chartGridColor,
                                            display:false
                                    },
                                    ticks: {
                                            fontColor: ChartConfig.axesColor,
                                            color: (darkMode === true ? 'white' : 'black')
                                    }
                            },
                            y: {
                                    grid: {
                                            color: ChartConfig.chartGridColor,
                                            display:false
                                    },
                                    ticks: {
                                            fontColor: ChartConfig.axesColor,
                                            color: (darkMode === true ? 'white' : 'black')
                                    }
                            },
                            
                    }
            };
            
            const options2 = {
                
                maintainAspectRatio: true, 
                    responsive: true,
                    indexAxis: 'x',
                 plugins:{
                        legend: {
                            display:false
                        }	
                },    
                scales: {
                            x: {
                                    grid: {
                                            color: ChartConfig.chartGridColor,
                                            display:false
                                    },
                                    ticks: {
                                            fontColor: ChartConfig.axesColor,
                                            color: (darkMode === true ? 'white' : 'black')
                                    }
                            },
                            y: {
                                    grid: {
                                            color: ChartConfig.chartGridColor,
                                            display:false
                                    },
                                    ticks: {
                                            fontColor: ChartConfig.axesColor,
                                            color: (darkMode === true ? 'white' : 'black')
                                    }
                            },
                            
                    }
            }


            const labels2 = dataLineDias;
            const data2  = {
              labels: labels2,
              datasets: [
                {
                  label: 'Nuevas',
                  data: dataLineNuevos,
                  borderColor: 'pink',
                  backgroundColor: 'pink',
                  //stack: 'combined',
                  type: 'bar'
                },
                {
                  label: 'Activas',
                  data: dataLineActivos,
                  borderColor: ChartConfig.color.warning,
                  backgroundColor: ChartConfig.color.warning
                  //stack: 'combined'
                }
              ]
            };
            
                const isDesktop = window.innerWidth > 768;
                
		return (
                        
			<div className="ecom-dashboard-wrapper">
                                {loading  && 
                                    <RctSectionLoader />
				}
				<Helmet>
					<title>{process.env.MIX_APP_NAME} | Dashboard</title>
					<meta name="description" content="Dashboard" />
				</Helmet>
				<PageTitleBar title={<IntlMessages id="sidebar.dashboard" />} match={match} />
                                {!loading &&
                                <Fragment>
                                <Alert className="d-flex align-items-center" color="success" isOpen={this.state.visible} toggle={this.onDismiss}>
                                <i className="zmdi zmdi-check check-circle"></i> Última actividad registrada: {format(parseISO(last_activity), 'dd/MM/yyyy')} a las {format(parseISO(last_activity), 'H:mm')}
                                </Alert>
                                
                                 <Accordion  className="mb-30 panel" defaultExpanded>
                                        <AccordionSummary expandIcon={<i className="zmdi zmdi-chevron-down"></i>} className="m-0 panel-heading">
                                                <h4>Bienvenido</h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                                <p>
                                                       Sistema de RRHH y Control para Seguridad SG, si detecta o experimenta algún problema favor enviar un mail a pablo.cruz@aysentf.cl o nikosk8r@gmail.com, o contáctese 
                                                       al número +56 9 88306126
                                                </p>
                                        </AccordionDetails>
                                </Accordion>
                                
                                <div className="row">
					
				<RctCollapsibleCard
                                        customClasses=""
                                        colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                                        heading='Enlaces Rápidos'
                                        fullBlock
                                     >
                                    <RctCardContent>
                                        <FormControl  fullWidth > 
                                            <FormGroup>
                                                <MatButton  component={Link} to="/app/control/control-management" variant="contained" className="btn btn-block btn-warning mr-10 mb-10 text-white w-100">Control</MatButton>
                                           </FormGroup>
                                           <FormGroup>
                                                <MatButton component={Link} to="/app/users/user-management" variant="contained" className="btn btn-block btn-secondary mr-10 mb-10 text-white w-100">Gestión de Usuarios</MatButton>
                                            </FormGroup>
                                            <FormGroup>
                                                <MatButton component={Link} to="/app/documents/documents-management"  variant="contained" color="primary" className="btn btn-block mr-10 mb-10 text-white w-100">Gestión de Documentos</MatButton>
                                            </FormGroup>
                                            <FormGroup>
                                                <MatButton component={Link} to="/app/seguimiento/seguimiento-management"  variant="contained" className="btn btn-block btn-success mr-10 mb-10 text-black w-100">Gestión de Seguimiento</MatButton>
                                            </FormGroup>
                                        </FormControl>
                                     </RctCardContent>
                                    </RctCollapsibleCard>
                                    
                                    {localStorage.getItem("rol") == 'administrador' &&
                                        <RctCollapsibleCard
                                        customClasses=""
                                        cardStyle={ isDesktop ? {height: '400px'} : ''}
                                        colClasses="col-sm-12 col-md-8 col-lg-8 w-xs-full"
                                        heading={'Licencias por Zona'}
                                        onrefresh={this.onrefreshGraph2}
                                        fullBlock
                                     >
                                     {loadingGrafico2 ?
                                     <RctSectionLoader />
                                       : <RctCardContent>
                                       <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}>
                                               <div style={{
                                                display: 'flex',
                                                alignItems: 'left',
                                                justifyContent: 'center',
                                                marginRight:'65px'
                                              }}>
                                               <MuiPickersUtilsProvider  locale={es} utils={DateFnsUtils}>
                                                        <DatePicker
                                                                views={['year', 'month']}
                                                                label="Selecciona periodo"
                                                                autoOk
                                                                showTabs={true}
                                                                dateformat="MM-YYYY"
                                                                maxDate={maxDate}
                                                                minDate={minDate}
                                                                value={selectedDate}
                                                                onChange={this.handleDateChange}
                                                                showmonthyearpicker="true"
                                                                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                                                                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                                                        />
                                                    </MuiPickersUtilsProvider>  
                                                    </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}>
                                              
                                             <Badge onClick={(e) => this.cambiaData2(e,'CHILOE')} className="mb-10 mr-10 mt-10" color={activeChiloe2 === '' ? 'primary': 'secondary'} style={{ cursor: 'pointer', textDecoration: activeChiloe2 }} >CHILOE ({totalChiloe2})</Badge>
                                             <Badge onClick={(e) => this.cambiaData2(e,'PMONTT')} className="mb-10 mr-10 mt-10"  color={activePmontt2 === '' ? 'primary': 'secondary'} style={{cursor: 'pointer', textDecoration: activePmontt2 }} >P. MONTT ({totalPmontt2})</Badge>
                                             <Badge onClick={(e) => this.cambiaData2(e,'AYSEN')} className="mb-10 mr-10 mt-10" color={activeAysen2 === '' ? 'primary': 'secondary'} style={{cursor: 'pointer', textDecoration: activeAysen2 }} >AYSÉN ({totalAysen2})</Badge>
                                          </div>
                                          </div>
                                        <FormControl   fullWidth >
                                            <Line
                                                style={{ marginTop: '20px' }}
                                                type="line"
                                                width={160}
                                                height={35}
                                                options={options2}
                                                data={data2}
                                              />
                                        </FormControl>
                                        </RctCardContent> }
                                    </RctCollapsibleCard>
                                    }
                                    {localStorage.getItem("rol") == 'administrador' &&
                                        <RctCollapsibleCard
                                        cardStyle={ isDesktop ? {height: '550px'} : ''}
                                        customClasses=""
                                        colClasses="col-sm-12 col-md-12 col-lg-12 w-xs-full"
                                        heading={'Usuarios por Instalación Activos ('+totalTodos+')'}
                                        onrefresh={this.onrefreshGraph1}
                                        fullBlock
                                     >
                                     {loadingGrafico1 ?
                                        <RctSectionLoader />
                                       : <RctCardContent>
                                        <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}>
                                             <Badge onClick={(e) => this.cambiaData(e,'CHILOE')} className="mb-10 mr-10" color={activeChiloe === '' ? 'primary': 'secondary'} style={{ cursor: 'pointer', textDecoration: activeChiloe }} >CHILOE ({totalChiloe})</Badge>
                                             <Badge onClick={(e) => this.cambiaData(e,'PMONTT')} className="mb-10 mr-10"  color={activePmontt === '' ? 'primary': 'secondary'} style={{cursor: 'pointer', textDecoration: activePmontt }} >P. MONTT ({totalPmontt})</Badge>
                                             <Badge onClick={(e) => this.cambiaData(e,'AYSEN')} className="mb-10 mr-10" color={activeAysen === '' ? 'primary': 'secondary'} style={{cursor: 'pointer', textDecoration: activeAysen }} >AYSÉN ({totalAysen})</Badge>
                                          </div>
                                        <FormControl  fullWidth >
                                            <Bar height={80} data={data} options={options} />
                                        </FormControl>
                                        </RctCardContent> }
                                    </RctCollapsibleCard>
                                    }
                                </div>
                                
                                </Fragment>    
                                         
                                }
				
                        </div>
                     
		);
              }   
}

const mapStateToProps = ({ settings }) => {
	return settings;
};

export default connect(mapStateToProps, {})(Dashboard);
